import { SVGProps } from 'react';

export const DevelopersIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.82812 18.375C4.96875 18.375 4.3151 18.151 3.86719 17.7031C3.42448 17.2552 3.20312 16.599 3.20312 15.7344V3.99219C3.20312 3.13281 3.42448 2.47656 3.86719 2.02344C4.3151 1.57031 4.96875 1.34375 5.82812 1.34375H9.63281C9.98177 1.34375 10.2891 1.36979 10.5547 1.42188C10.8203 1.46875 11.0625 1.55469 11.2812 1.67969C11.5052 1.80469 11.7318 1.98698 11.9609 2.22656L15.9141 6.23438C16.1536 6.47917 16.3359 6.71354 16.4609 6.9375C16.5911 7.16146 16.6797 7.40885 16.7266 7.67969C16.7734 7.94531 16.7969 8.26823 16.7969 8.64844V15.7344C16.7969 16.599 16.5729 17.2552 16.125 17.7031C15.6771 18.151 15.026 18.375 14.1719 18.375H5.82812ZM6 16.6406H13.9922C14.3516 16.6406 14.6172 16.5495 14.7891 16.3672C14.9661 16.1901 15.0547 15.9297 15.0547 15.5859V8.97656H11.0312C10.4219 8.97656 9.96875 8.82812 9.67188 8.53125C9.375 8.23438 9.22656 7.78125 9.22656 7.17188V3.08594H6.00781C5.64844 3.08594 5.38021 3.17708 5.20312 3.35938C5.02604 3.54167 4.9375 3.80208 4.9375 4.14062V15.5859C4.9375 15.9297 5.02604 16.1901 5.20312 16.3672C5.38021 16.5495 5.64583 16.6406 6 16.6406ZM11.1797 7.5H14.8672L10.7031 3.27344V7.02344C10.7031 7.1901 10.7396 7.3125 10.8125 7.39062C10.8906 7.46354 11.013 7.5 11.1797 7.5Z"
                fill="currentColor"
            />
        </svg>
    )
}
