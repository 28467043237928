import React, { useEffect } from 'react'
import useIsElementVisible from '../../hooks/useIsElementVisible'
import { SHORTEN_SHIMMERS_DATA } from '../../utils/mocks/shimmers'
import { ApplicationCard } from '../ApplicationCard/ApplicationCard'

interface IVisibilityHandler {
    handleVisibilityChange: (visible: boolean) => void
}

export const VisibilityHandler: React.FC<IVisibilityHandler> = (props) => {
    const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
    const isElementVisible = useIsElementVisible(ref);

    useEffect(() => {
        if (isElementVisible) {
            props.handleVisibilityChange(isElementVisible);
        }
    }, [props.handleVisibilityChange, isElementVisible])

    return (
        <div ref={(ref) => setRef(ref)}>
            {
                SHORTEN_SHIMMERS_DATA.map((item, index) => {
                    return (
                        <ApplicationCard key={index} {...item} noControls/>
                    )
                })
            }
        </div>
    );
};
