import React from 'react';
import numeral from 'numeral';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';
import useSWRImmutable from 'swr/immutable';

import { useApplicationContext } from '../../../dataSource/ApplicationContext';
import { Diamond } from '../../../svg/Diamond';
import { Body, Caption, Heading } from '../../../uilib/Typography/Typography';
import { getJettonInfo } from '../../../utils/api/graphql/getJettonInfo';
import { getToncoinPrice } from '../../../utils/api/requests';

import styles from './styles.module.scss';
import { calcPercentage, formatPrice, getMarketDataFromQuery } from './utils'

export const JettonInfo  = () => {
    const { t } = useTranslation();
    const {
        application,
        isLoading,
    } = useApplicationContext();

    const jettonAddress = application?.attributes.jetton?.data?.attributes.address;
    const jettonName = application?.attributes.jetton?.data?.attributes.ticker;
    const jettonInfo = useSWRImmutable(jettonAddress, getJettonInfo);
    const toncoinPrice = useSWRImmutable("toncoinPrice", getToncoinPrice);

    if (!jettonAddress || jettonInfo?.data?.jettonInfo.length === 0) {
        return null;
    }

    const {
        priceFeed,
        lastActualPrice,
        last24hPrice,
        last7dPrice,
    } = getMarketDataFromQuery(jettonInfo?.data);

    const last24hPricePercentage = calcPercentage(lastActualPrice, last24hPrice);
    const last7dPricePercentage = calcPercentage(lastActualPrice, last7dPrice);

    const chartColor = last7dPricePercentage.positive
        ? "var(--color_green)"
        : "var(--color_red)";
    const isDataLoading =  isLoading || !jettonInfo?.data || toncoinPrice.isLoading;

    return isDataLoading
        ? <div className={styles.loader}></div>
        : (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
            >
                <div className={styles.header}>
                    <Heading
                        type={"h4"}
                        weight={'bold'}
                        className={styles.heading}
                    >
                        {t("info")}
                    </Heading>
                </div>
                <div className={styles.root}>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("name")}
                        </Caption>
                        <Heading
                            type={"h4"}
                            weight={"semi-bold"}
                            className={styles.heading}
                        >
                            {jettonName}
                        </Heading>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("tokenPrice")}
                        </Caption>
                        <Heading
                            type={"h4"}
                            weight={"semi-bold"}
                            className={styles.heading}
                        >
                            <Diamond />&nbsp;{numeral(jettonInfo?.data?.jettonInfo[0].price || 0).format('0,0.000')}
                        </Heading>
                        <Caption
                            level={2}
                            weight={"regular"}
                            className={styles.caption}
                        >
                            {formatPrice(jettonInfo?.data?.jettonInfo[0].price || 0, toncoinPrice.data)}
                        </Caption>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("marketCap")}
                        </Caption>
                        <Heading
                            type={"h4"}
                            weight={"semi-bold"}
                            className={styles.heading}
                        >
                            <Diamond />&nbsp;{numeral(jettonInfo?.data?.jettonInfo[0].total_tvl || 0).format('0,0')}
                        </Heading>
                        <Caption
                            level={2}
                            weight={"regular"}
                            className={styles.caption}
                        >
                            {formatPrice(jettonInfo?.data?.jettonInfo[0].total_tvl || 0, toncoinPrice.data)}
                        </Caption>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("24H")}
                        </Caption>
                        <Heading
                            type={"h4"}
                            weight={"semi-bold"}
                            className={classNames(styles.heading, last24hPricePercentage.positive ? styles.positive : styles.negative)}
                        >
                            {last24hPricePercentage.value}
                        </Heading>
                        <Caption
                            level={2}
                            weight={"regular"}
                            className={styles.caption}
                        >
                            {(last24hPricePercentage.positive ? "+" : "") + formatPrice(lastActualPrice - last24hPrice, toncoinPrice.data)}
                        </Caption>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("7d")}
                        </Caption>
                        <Heading
                            type={"h4"}
                            weight={"semi-bold"}
                            className={classNames(styles.heading, last7dPricePercentage.positive ? styles.positive : styles.negative)}
                        >
                            {last7dPricePercentage.value}
                        </Heading>
                        <Caption
                            level={2}
                            weight={"regular"}
                            className={styles.caption}
                        >
                            {(last7dPricePercentage.positive ? "+" : "") + formatPrice(lastActualPrice - last7dPrice, toncoinPrice.data)}
                        </Caption>
                    </div>
                    <div className={styles.divider}/>
                    <div className={styles.block}>
                        <Caption
                            level={2}
                            weight={"semi-bold"}
                            className={styles.caption}
                            caps
                        >
                            {t("last7d")}
                        </Caption>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={200}
                                height={60}
                                data={priceFeed}
                                margin={{
                                    top: 0,
                                    right: 4,
                                    left: 4,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="var(--color_green)" stopOpacity={0.12}/>
                                        <stop offset="95%" stopColor={chartColor} stopOpacity={0.0}/>
                                    </linearGradient>
                                </defs>
                                <Area
                                    type="monotone"
                                    dataKey="price"
                                    stroke={chartColor}
                                    fill="url(#colorUv)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className={styles.separatorWrapper}>
                    <div className={styles.separator}></div>
                </div>
            </motion.div>
        );
}
