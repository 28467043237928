import { SVGProps } from 'react'

export const AddIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg 
            width="20" 
            height="20" 
            viewBox="0 0 20 20"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10 18.008a7.824 7.824 0 0 1-3.148-.64 8.228 8.228 0 0 1-2.602-1.759 8.363 8.363 0 0 1-1.766-2.593 7.927 7.927 0 0 1-.632-3.157c0-1.114.21-2.161.632-3.14a8.404 8.404 0 0 1 1.758-2.602A8.227 8.227 0 0 1 6.844 2.36a7.824 7.824 0 0 1 3.148-.64c1.115 0 2.164.213 3.149.64a8.112 8.112 0 0 1 2.601 1.758 8.338 8.338 0 0 1 1.766 2.602c.427.979.64 2.026.64 3.14 0 1.12-.213 2.172-.64 3.157a8.363 8.363 0 0 1-1.766 2.593 8.136 8.136 0 0 1-2.594 1.758 7.824 7.824 0 0 1-3.148.64zm0-1.79c.88 0 1.703-.163 2.469-.491a6.441 6.441 0 0 0 2.031-1.36 6.277 6.277 0 0 0 1.86-4.508c0-.88-.167-1.703-.5-2.468a6.377 6.377 0 0 0-1.368-2.032 6.284 6.284 0 0 0-4.5-1.859c-.88 0-1.706.164-2.476.492A6.399 6.399 0 0 0 5.492 5.36a6.287 6.287 0 0 0-1.844 4.5c0 .886.162 1.714.485 2.485.328.765.784 1.44 1.367 2.023a6.466 6.466 0 0 0 2.023 1.36c.771.328 1.597.492 2.477.492zM6.39 9.86c0-.234.074-.424.22-.57a.757.757 0 0 1 .57-.226h2.023V7.038a.761.761 0 0 1 .781-.789c.235 0 .425.073.57.219a.757.757 0 0 1 .227.57v2.024h2.031c.23 0 .417.075.563.226a.772.772 0 0 1 .219.57.778.778 0 0 1-.219.563.761.761 0 0 1-.563.219h-2.03v2.03c0 .23-.076.418-.227.563a.771.771 0 0 1-.57.22.778.778 0 0 1-.563-.22.762.762 0 0 1-.219-.562V10.64H7.18a.788.788 0 0 1-.57-.22.778.778 0 0 1-.22-.562z" 
                fill="currentColor"
            />
        </svg>

    )
}