import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TWACategory } from '../../../../types/entities';
import { useApplicationContext } from '../../../dataSource/ApplicationContext';
import { Button } from '../../../uilib/Button/Button';
import { Body, Heading } from '../../../uilib/Typography/Typography';

import styles from './styles.module.scss';

interface ITwaRecommendationsHeaderProps {
    category?: TWACategory;
}

export const TWARecommendationsHeader: React.FC<ITwaRecommendationsHeaderProps> = (props) => {
    const { isLoading } = useApplicationContext();
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <div className={styles.header}>
                <div className={styles.shine}></div>
            </div>
        )
    }

    return (
        <div className={styles.header}>
            <Heading type={"h4"} weight={'bold'}>
                {t("youMayAlsoLike")}
            </Heading>
            {
                props.category?.attributes.path && (
                    <Link to={"/" + props.category?.attributes.path}>
                        <Button type={"tertiary"}>
                            <Body
                                weight={"regular"}
                                className={styles.label}
                            >
                                {t("seeAll")}
                            </Body>
                        </Button>
                    </Link>
                )
            }
        </div>
    )
}
