import classNames from 'classnames';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useApplicationContext } from '../../../dataSource/ApplicationContext';
import { useTWAPlatform } from '../../../hooks';
import useIsElementVisible from '../../../hooks/useIsElementVisible';
import { useOpenAppCommand } from '../../../hooks/app/useOpenAppCommand';
import { useShareAppCommand } from '../../../hooks/app/useShareAppCommand';
import { SharingIcon } from '../../../svg/SharingIcon';
import { Button } from '../../../uilib/Button/Button';
import { AnimateEnter } from '../../../uilib/Enter/Enter';
import { ProgressiveImage } from '../../../uilib/ProgressiveImage/ProgressiveImage';
import { Heading, SubHeadline } from '../../../uilib/Typography/Typography';

import styles from './styles.module.scss';

export const AppHeader = () => {
    const { t } = useTranslation();
    const {
        isLoading,
        application,
    } = useApplicationContext();
    const platform = useTWAPlatform();

    const { createOpenAppCommand } = useOpenAppCommand();
    const { createShareAppCommand } = useShareAppCommand();
    const [ref, setRef] = React.useState<Element | null>(null);
    const isElementVisible = useIsElementVisible(ref);

    const handleOpenApp = React.useCallback(() => {
        window.Telegram?.WebApp?.HapticFeedback?.impactOccurred('light');

        createOpenAppCommand(application, 'App Screen');
    }, [createOpenAppCommand, application]);

    useEffect(() => {
        window.Telegram?.WebApp?.MainButton.offClick(handleOpenApp);

        if (isElementVisible || isElementVisible === null) {
            window.Telegram?.WebApp?.MainButton.hide();
        } else {
            window.Telegram?.WebApp?.HapticFeedback?.impactOccurred('light');
            window.Telegram?.WebApp?.MainButton?.setParams({ text: 'Open' });
            window.Telegram?.WebApp?.MainButton?.onClick(handleOpenApp);
            window.Telegram?.WebApp?.MainButton?.show();
        }

        return () => {
            window.Telegram?.WebApp?.MainButton.offClick(handleOpenApp);
            window.Telegram?.WebApp?.MainButton.hide();
        }
    }, [isElementVisible]);

    return (
        <div className={styles.root}>
            <ProgressiveImage
                width={162}
                height={162}
                src={application?.attributes?.icon?.data?.attributes.url}
                alt={application?.attributes?.icon?.data?.attributes.name || ""}
                className={classNames(styles.image, isLoading && styles.shine)}
            />
            <div className={styles.content}>
                <AnimateEnter className={styles.container}>
                    <Heading
                        type={'h3'}
                        weight={"semi-bold"}
                        className={
                            classNames(
                                isLoading && styles.shine,
                                isLoading && styles.shine__shineText,
                            )
                        }
                    >
                        {
                            isLoading
                                ? 'App is loading, please wait'
                                : application?.attributes.title
                        }
                    </Heading>
                    <SubHeadline
                        level={1}
                        weight={"regular"}
                        className={
                            classNames(
                                styles.subheadline,
                                isLoading && styles.shine,
                                isLoading && styles.shine__shineText,
                            )
                        }
                    >
                        {
                            isLoading
                                ? 'App is loading, please wait'
                                : application?.attributes.description
                        }
                    </SubHeadline>
                </AnimateEnter>
                <div
                    className={styles.controls}
                    ref={(ref) => setRef(ref)}
                >
                    <Button
                        shine={isLoading}
                        type={"primary"}
                        onClick={handleOpenApp}
                    >
                        <SubHeadline
                            caps
                            level={1}
                            weight={"semi-bold"}
                        >
                            {t("open")}
                        </SubHeadline>
                    </Button>
                    {!isLoading && platform !== 'macos' && platform !== 'web' && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2 }}
                            className={styles.sharingIcon}
                        >
                            <Button
                                shine={isLoading}
                                type={"tertiary"}
                                className={styles.sharingButton}
                                onClick={() => createShareAppCommand(application)}
                            >
                                <SharingIcon />
                            </Button>
                        </motion.div>
                    )}
                </div>
            </div>
        </div>
    )
}
