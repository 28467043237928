import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { ConfigProvider } from '../../dataSource/ConfigContext';
import { DataProvider } from '../../dataSource/DataContext/DataContext';
import { useMiniAppInitAction } from '../../hooks/init/useMiniAppInitAction';
import { useDeeplinkMiddleware } from '../../hooks/useDeeplinkMiddleware';
import { NavigationEvents } from './NavigationEvents';

export default function Layout() {
    useDeeplinkMiddleware();

    const {
        config,
        rankings,
        navigationConfig,
        mainPageBlockConfig,
        categories,
    } = useMiniAppInitAction();

    return (
        <>
            <NavigationEvents/>
            <ScrollRestoration/>
            <ConfigProvider config={config?.data?.data}>
                <DataProvider
                    navigationConfig={navigationConfig.data}
                    mainPageBlockConfig={mainPageBlockConfig?.data ?? []}
                    categories={categories?.data?.data}
                    appsRanking={rankings?.data || []}
                >
                    <Outlet />
                </DataProvider>
            </ConfigProvider>
        </>
    );
}
