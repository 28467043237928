import { SVGProps } from 'react';

export const TheOpenLeagueIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11.9579 8.16552L3.42369 8.16552C2.90006 8.16552 2.58156 8.74231 2.86048 9.18547L10.7293 21.6878C11.0848 22.2527 11.9579 22.0008 11.9579 21.3333V8.16552Z" fill="currentColor"/>
            <path d="M11.9509 2.66652V8.17072L20.5756 8.17882C21.2486 8.17945 21.4971 7.29496 20.9223 6.94495L12.9625 2.09812C12.519 1.82809 11.9509 2.1473 11.9509 2.66652Z" fill="currentColor"/>
        </svg>
    )
}
