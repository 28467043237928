import React from 'react';
import { Ripple } from '../../../../../uilib/Ripple/Ripple';
import styles from './page.module.scss';

interface IRowProps {
    left: React.ReactNode,
    right: React.ReactNode,
    onClick: () => void,
}

export const Row: React.FC<IRowProps> = (props) => {
    return (
        <Ripple className={styles.root} onClick={props.onClick}>
            <div className={styles.container}>
                <div className={styles.left}>
                    {props.left}
                </div>
                <div className={styles.right}>
                    {props.right}
                </div>
            </div>
        </Ripple>
    );
}
