// @ts-nocheck
import { TWAApplication } from '../../../types'

export const MOCK_RECENTLY_LAUNCHED_APPS: Array<TWAApplication> = [
    {
        "id":163,
        "attributes":{
            "title":"Paint Bot",
            "description":"This bot allows you to draw graffitis and send them to any chat.",
            "url":"https://t.me/PaintBot",
            "path":"paint-bot",
            "createdAt":"2023-11-17T19:34:56.876Z",
            "updatedAt":"2023-11-17T19:35:07.347Z",
            "publishedAt":"2023-11-17T19:34:58.500Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":315,
                    "attributes":{
                        "name":"2023-11-17 22.34.11.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_22_34_11_23e3dfceb4.jpg",
                                "hash":"small_2023_11_17_22_34_11_23e3dfceb4",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 22.34.11.jpg",
                                "path":null,
                                "size":23.64,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_22_34_11_23e3dfceb4.jpg",
                                "hash":"thumbnail_2023_11_17_22_34_11_23e3dfceb4",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 22.34.11.jpg",
                                "path":null,
                                "size":4.37,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_22_34_11_23e3dfceb4",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":33.13,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T19:34:18.128Z",
                        "updatedAt":"2023-11-17T19:34:18.128Z"
                    }
                }
            }
        }
    },
    {
        "id":128,
        "attributes":{
            "title":"Animoji Center",
            "description":"Explore stickers inside Telegram.",
            "url":"https://t.me/tanimoji_bot",
            "path":"animoji-center",
            "createdAt":"2023-11-01T16:55:20.099Z",
            "updatedAt":"2023-11-01T16:55:21.456Z",
            "publishedAt":"2023-11-01T16:55:21.449Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":"https://t.me/tanimoji_bot/center",
            "community_url":null,
            "icon":{
                "data":{
                    "id":188,
                    "attributes":{
                        "name":"2023-11-01 19.54.28.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_01_19_54_28_d29e1689fe.jpg",
                                "hash":"small_2023_11_01_19_54_28_d29e1689fe",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-01 19.54.28.jpg",
                                "path":null,
                                "size":8.59,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_01_19_54_28_d29e1689fe.jpg",
                                "hash":"thumbnail_2023_11_01_19_54_28_d29e1689fe",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-01 19.54.28.jpg",
                                "path":null,
                                "size":2.3,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_01_19_54_28_d29e1689fe",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":11.5,
                        "url": "",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-01T16:54:39.811Z",
                        "updatedAt":"2023-11-01T16:54:39.811Z"
                    }
                }
            }
        }
    },
    {
        "id":20,
        "attributes":{
            "title":"Mobile",
            "description":"Buy eSim for any country with credit card or TON.",
            "url":"https://t.me/Mobile/",
            "path":"mobile",
            "createdAt":"2023-08-07T18:04:10.534Z",
            "updatedAt":"2023-11-03T18:46:15.739Z",
            "publishedAt":"2023-08-07T18:04:12.524Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":41,
                    "attributes":{
                        "name":"2023-08-07 21.03.34.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_08_07_21_03_34_f1379ed201.jpg",
                                "hash":"small_2023_08_07_21_03_34_f1379ed201",
                                "mime":"image/jpeg",
                                "name":"small_2023-08-07 21.03.34.jpg",
                                "path":null,
                                "size":10.6,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_08_07_21_03_34_f1379ed201.jpg",
                                "hash":"thumbnail_2023_08_07_21_03_34_f1379ed201",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-08-07 21.03.34.jpg",
                                "path":null,
                                "size":2.59,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_08_07_21_03_34_f1379ed201",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":13.93,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-08-07T18:03:49.830Z",
                        "updatedAt":"2023-08-07T18:03:49.830Z"
                    }
                }
            }
        }
    },
    {
        "id":119,
        "attributes":{
            "title":"PlayDeck",
            "description":"PlayDeck is the ultimate Telegram games catalog.",
            "url":"https://t.me/playdeckbot",
            "path":"play-deck",
            "createdAt":"2023-10-25T09:31:47.023Z",
            "updatedAt":"2023-11-03T18:47:13.950Z",
            "publishedAt":"2023-10-25T09:31:52.692Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":146,
                    "attributes":{
                        "name":"2023-10-25 12.30.35.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_10_25_12_30_35_cb974bc6d3.jpg",
                                "hash":"small_2023_10_25_12_30_35_cb974bc6d3",
                                "mime":"image/jpeg",
                                "name":"small_2023-10-25 12.30.35.jpg",
                                "path":null,
                                "size":10.39,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_10_25_12_30_35_cb974bc6d3.jpg",
                                "hash":"thumbnail_2023_10_25_12_30_35_cb974bc6d3",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-10-25 12.30.35.jpg",
                                "path":null,
                                "size":2.82,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_10_25_12_30_35_cb974bc6d3",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":13.13,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-10-25T09:30:56.021Z",
                        "updatedAt":"2023-10-25T09:30:56.021Z"
                    }
                }
            }
        }
    },
    {
        "id":159,
        "attributes":{
            "title":"Chess Now",
            "description":"Engage in thrilling chess matches with friends, colleagues, or fellow group members without leaving Telegram.",
            "url":"https://t.me/ChessNowBot",
            "path":"chess-now",
            "createdAt":"2023-11-17T16:57:36.314Z",
            "updatedAt":"2023-11-17T18:50:52.924Z",
            "publishedAt":"2023-11-17T18:50:52.920Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":311,
                    "attributes":{
                        "name":"2023-11-17 19.57.25.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_57_25_988e4b4d3c.jpg",
                                "hash":"small_2023_11_17_19_57_25_988e4b4d3c",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.57.25.jpg",
                                "path":null,
                                "size":16.48,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_57_25_988e4b4d3c.jpg",
                                "hash":"thumbnail_2023_11_17_19_57_25_988e4b4d3c",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.57.25.jpg",
                                "path":null,
                                "size":3.27,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_57_25_988e4b4d3c",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":21.83,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:57:32.943Z",
                        "updatedAt":"2023-11-17T16:57:32.943Z"
                    }
                }
            }
        }
    },
    {
        "id":157,
        "attributes":{
            "title":"Naval Clash",
            "description":"Naval Clash a beloved childhood game for everyone. Now available on Telegram!",
            "url":"https://t.me/naval_clash_bot",
            "path":"naval-clash",
            "createdAt":"2023-11-17T16:55:02.905Z",
            "updatedAt":"2023-11-17T18:50:44.511Z",
            "publishedAt":"2023-11-17T18:50:44.507Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":309,
                    "attributes":{
                        "name":"2023-11-17 19.54.53.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_54_53_48059b0f02.jpg",
                                "hash":"small_2023_11_17_19_54_53_48059b0f02",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.54.53.jpg",
                                "path":null,
                                "size":31.67,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_54_53_48059b0f02.jpg",
                                "hash":"thumbnail_2023_11_17_19_54_53_48059b0f02",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.54.53.jpg",
                                "path":null,
                                "size":7.72,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_54_53_48059b0f02",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":37.63,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:54:58.785Z",
                        "updatedAt":"2023-11-17T16:54:58.785Z"
                    }
                }
            }
        }
    },
    {
        "id":158,
        "attributes":{
            "title":"StickerWiz",
            "description":"Create custom stickers with your own text using cool graphic interface.",
            "url":"https://t.me/stkrz_bot",
            "path":"sticker-wiz",
            "createdAt":"2023-11-17T16:56:38.415Z",
            "updatedAt":"2023-11-17T18:50:47.842Z",
            "publishedAt":"2023-11-17T18:50:47.837Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":310,
                    "attributes":{
                        "name":"2023-11-17 19.56.13.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_56_13_657ec24a93.jpg",
                                "hash":"small_2023_11_17_19_56_13_657ec24a93",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.56.13.jpg",
                                "path":null,
                                "size":18.92,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_56_13_657ec24a93.jpg",
                                "hash":"thumbnail_2023_11_17_19_56_13_657ec24a93",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.56.13.jpg",
                                "path":null,
                                "size":3.74,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_56_13_657ec24a93",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":26.83,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:56:25.010Z",
                        "updatedAt":"2023-11-17T16:56:25.010Z"
                    }
                }
            }
        }
    },
    {
        "id":162,
        "attributes":{
            "title":"Callegram",
            "description":"Use this bot to schedule calls with users natively in Telegram.",
            "url":"https://t.me/CallegramBot",
            "path":"callegram",
            "createdAt":"2023-11-17T18:45:59.848Z",
            "updatedAt":"2023-11-17T18:46:01.267Z",
            "publishedAt":"2023-11-17T18:46:01.262Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":314,
                    "attributes":{
                        "name":"Screenshot 2023-11-17 at 9.45.44 PM.png",
                        "alternativeText":null,
                        "caption":null,
                        "width":1278,
                        "height":1276,
                        "formats":{
                            "large":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/large_Screenshot_2023_11_17_at_9_45_44_PM_0817890251.png",
                                "hash":"large_Screenshot_2023_11_17_at_9_45_44_PM_0817890251",
                                "mime":"image/png",
                                "name":"large_Screenshot 2023-11-17 at 9.45.44 PM.png",
                                "path":null,
                                "size":691.2,
                                "width":1000,
                                "height":998
                            },
                            "small":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_Screenshot_2023_11_17_at_9_45_44_PM_0817890251.png",
                                "hash":"small_Screenshot_2023_11_17_at_9_45_44_PM_0817890251",
                                "mime":"image/png",
                                "name":"small_Screenshot 2023-11-17 at 9.45.44 PM.png",
                                "path":null,
                                "size":128.27,
                                "width":500,
                                "height":499
                            },
                            "medium":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/medium_Screenshot_2023_11_17_at_9_45_44_PM_0817890251.png",
                                "hash":"medium_Screenshot_2023_11_17_at_9_45_44_PM_0817890251",
                                "mime":"image/png",
                                "name":"medium_Screenshot 2023-11-17 at 9.45.44 PM.png",
                                "path":null,
                                "size":342.22,
                                "width":750,
                                "height":749
                            },
                            "thumbnail":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_Screenshot_2023_11_17_at_9_45_44_PM_0817890251.png",
                                "hash":"thumbnail_Screenshot_2023_11_17_at_9_45_44_PM_0817890251",
                                "mime":"image/png",
                                "name":"thumbnail_Screenshot 2023-11-17 at 9.45.44 PM.png",
                                "path":null,
                                "size":17.13,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"Screenshot_2023_11_17_at_9_45_44_PM_0817890251",
                        "ext":".png",
                        "mime":"image/png",
                        "size":195.91,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T18:45:50.294Z",
                        "updatedAt":"2023-11-17T18:45:50.294Z"
                    }
                }
            }
        }
    },
    {
        "id":154,
        "attributes":{
            "title":"InstaQuiz",
            "description":"Multiplayer quiz game!",
            "url":"https://t.me/IQuizzBot",
            "path":"insta-quiz",
            "createdAt":"2023-11-17T16:48:21.292Z",
            "updatedAt":"2023-11-17T18:50:09.891Z",
            "publishedAt":"2023-11-17T18:50:09.887Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":306,
                    "attributes":{
                        "name":"2023-11-17 19.48.09.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_48_09_6a7b8377a1.jpg",
                                "hash":"small_2023_11_17_19_48_09_6a7b8377a1",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.48.09.jpg",
                                "path":null,
                                "size":17.09,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_48_09_6a7b8377a1.jpg",
                                "hash":"thumbnail_2023_11_17_19_48_09_6a7b8377a1",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.48.09.jpg",
                                "path":null,
                                "size":4.2,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_48_09_6a7b8377a1",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":23.11,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:48:14.637Z",
                        "updatedAt":"2023-11-17T16:48:14.637Z"
                    }
                }
            }
        }
    },
    {
        "id":127,
        "attributes":{
            "title":"Storm Trade Bot",
            "description":"Go beyond crypto and trade crypto, stock, forex and commodities with Storm Trading Bot!",
            "url":"https://t.me/StormTradeBot",
            "path":"storm-trade-bot",
            "createdAt":"2023-11-01T10:59:17.835Z",
            "updatedAt":"2023-11-01T10:59:19.974Z",
            "publishedAt":"2023-11-01T10:59:19.968Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":187,
                    "attributes":{
                        "name":"2023-11-01 13.57.29.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_01_13_57_29_2a6b23695f.jpg",
                                "hash":"small_2023_11_01_13_57_29_2a6b23695f",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-01 13.57.29.jpg",
                                "path":null,
                                "size":12.2,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_01_13_57_29_2a6b23695f.jpg",
                                "hash":"thumbnail_2023_11_01_13_57_29_2a6b23695f",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-01 13.57.29.jpg",
                                "path":null,
                                "size":2.94,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_01_13_57_29_2a6b23695f",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":16.61,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-01T10:57:51.730Z",
                        "updatedAt":"2023-11-01T10:57:51.730Z"
                    }
                }
            }
        }
    },
    {
        "id":132,
        "attributes":{
            "title":"ScreenSuggest",
            "description":"Your go-to app for personalized movie and TV show recommendations!",
            "url":"https://t.me/ScreenSuggest_bot",
            "path":"screen-suggest",
            "createdAt":"2023-11-01T17:54:18.714Z",
            "updatedAt":"2023-11-01T17:54:20.392Z",
            "publishedAt":"2023-11-01T17:54:20.384Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":"https://t.me/ScreenSuggest_bot/open",
            "community_url":null,
            "icon":{
                "data":{
                    "id":192,
                    "attributes":{
                        "name":"2023-11-01 20.53.26.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_01_20_53_26_51a0b013e5.jpg",
                                "hash":"small_2023_11_01_20_53_26_51a0b013e5",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-01 20.53.26.jpg",
                                "path":null,
                                "size":43.36,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_01_20_53_26_51a0b013e5.jpg",
                                "hash":"thumbnail_2023_11_01_20_53_26_51a0b013e5",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-01 20.53.26.jpg",
                                "path":null,
                                "size":6.15,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_01_20_53_26_51a0b013e5",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":63.45,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-01T17:53:35.357Z",
                        "updatedAt":"2023-11-01T17:53:35.357Z"
                    }
                }
            }
        }
    },
    {
        "id":153,
        "attributes":{
            "title":"Office Gym Bot",
            "description":"Workplace workout reminder with a huge collection of exercises.",
            "url":"https://t.me/officegymbot",
            "path":"office-gym-bot",
            "createdAt":"2023-11-17T16:45:54.356Z",
            "updatedAt":"2023-11-17T18:50:07.221Z",
            "publishedAt":"2023-11-17T18:50:07.212Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":305,
                    "attributes":{
                        "name":"2023-11-17 19.45.34.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_45_34_d49653e772.jpg",
                                "hash":"small_2023_11_17_19_45_34_d49653e772",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.45.34.jpg",
                                "path":null,
                                "size":21.03,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_45_34_d49653e772.jpg",
                                "hash":"thumbnail_2023_11_17_19_45_34_d49653e772",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.45.34.jpg",
                                "path":null,
                                "size":3.94,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_45_34_d49653e772",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":29.67,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:45:49.904Z",
                        "updatedAt":"2023-11-17T16:45:49.904Z"
                    }
                }
            }
        }
    },
    {
        "id":6,
        "attributes":{
            "title":"Hell Sweep Game",
            "description":"First fantasy rogue-like game on Telegram.",
            "url":"https://t.me/HellSweep_bot/",
            "path":"hell-sweep-game",
            "createdAt":"2023-08-07T17:33:10.813Z",
            "updatedAt":"2023-09-03T10:57:31.687Z",
            "publishedAt":"2023-08-07T17:33:31.693Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":27,
                    "attributes":{
                        "name":"2023-08-07 20.32.39.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_08_07_20_32_39_8a4327cdf7.jpg",
                                "hash":"small_2023_08_07_20_32_39_8a4327cdf7",
                                "mime":"image/jpeg",
                                "name":"small_2023-08-07 20.32.39.jpg",
                                "path":null,
                                "size":18.67,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_08_07_20_32_39_8a4327cdf7.jpg",
                                "hash":"thumbnail_2023_08_07_20_32_39_8a4327cdf7",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-08-07 20.32.39.jpg",
                                "path":null,
                                "size":4.53,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_08_07_20_32_39_8a4327cdf7",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":24.45,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-08-07T17:32:49.045Z",
                        "updatedAt":"2023-08-07T17:32:49.045Z"
                    }
                }
            }
        }
    },
    {
        "id":1,
        "attributes":{
            "title":"Wallet",
            "description":"Purchase cryptocurrency by bank card, exchange, and transfer to other wallets.",
            "url":"https://t.me/wallet",
            "path":"wallet",
            "createdAt":"2023-08-06T14:04:14.236Z",
            "updatedAt":"2023-11-03T22:42:01.539Z",
            "publishedAt":"2023-08-06T14:07:57.069Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":"https://t.me/wallet/start",
            "community_url":null,
            "icon":{
                "data":{
                    "id":18,
                    "attributes":{
                        "name":"Image.png",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_Image_67e3a9cdab.png",
                                "hash":"small_Image_67e3a9cdab",
                                "mime":"image/png",
                                "name":"small_Image.png",
                                "path":null,
                                "size":99.69,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_Image_67e3a9cdab.png",
                                "hash":"thumbnail_Image_67e3a9cdab",
                                "mime":"image/png",
                                "name":"thumbnail_Image.png",
                                "path":null,
                                "size":11.34,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"Image_67e3a9cdab",
                        "ext":".png",
                        "mime":"image/png",
                        "size":22.62,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-08-06T14:00:34.753Z",
                        "updatedAt":"2023-08-28T09:24:30.508Z"
                    }
                }
            }
        }
    },
    {
        "id":15,
        "attributes":{
            "title":"Instant Games",
            "description":"Play multiplayer games, challenge friends, and compete for high scores.",
            "url":"https://t.me/InstantGames_bot/",
            "path":"instant-games",
            "createdAt":"2023-08-07T17:54:35.682Z",
            "updatedAt":"2023-11-15T04:59:32.619Z",
            "publishedAt":"2023-08-07T17:54:37.799Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":145,
                    "attributes":{
                        "name":"tele ava.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_tele_ava_76a95392c0.jpg",
                                "hash":"small_tele_ava_76a95392c0",
                                "mime":"image/jpeg",
                                "name":"small_tele ava.jpg",
                                "path":null,
                                "size":13.29,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_tele_ava_76a95392c0.jpg",
                                "hash":"thumbnail_tele_ava_76a95392c0",
                                "mime":"image/jpeg",
                                "name":"thumbnail_tele ava.jpg",
                                "path":null,
                                "size":3.12,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"tele_ava_76a95392c0",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":17.9,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-10-20T11:41:13.942Z",
                        "updatedAt":"2023-10-20T11:41:13.942Z"
                    }
                }
            }
        }
    },
    {
        "id":152,
        "attributes":{
            "title":"Movie Matcher",
            "description":"Swipe, match, and movie night sorted! ",
            "url":"https://t.me/movie_matcher_bot",
            "path":"movie-matcher",
            "createdAt":"2023-11-17T16:44:21.471Z",
            "updatedAt":"2023-11-17T18:49:56.049Z",
            "publishedAt":"2023-11-17T18:49:56.044Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":304,
                    "attributes":{
                        "name":"2023-11-17 19.43.55.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_43_55_b97b962e59.jpg",
                                "hash":"small_2023_11_17_19_43_55_b97b962e59",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.43.55.jpg",
                                "path":null,
                                "size":10.02,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_43_55_b97b962e59.jpg",
                                "hash":"thumbnail_2023_11_17_19_43_55_b97b962e59",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.43.55.jpg",
                                "path":null,
                                "size":2.61,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_43_55_b97b962e59",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":13.11,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:44:04.530Z",
                        "updatedAt":"2023-11-17T16:44:04.530Z"
                    }
                }
            }
        }
    },
    {
        "id":14,
        "attributes":{
            "title":"Punk City",
            "description":"$PUNK GameFi ecosystem. Runs on The Open Network.",
            "url":"https://t.me/PunkCity2094bot/",
            "path":"punk-city",
            "createdAt":"2023-08-07T17:52:15.202Z",
            "updatedAt":"2023-11-03T18:50:51.728Z",
            "publishedAt":"2023-08-07T17:52:27.808Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":60,
                    "attributes":{
                        "name":"Frame 267.png",
                        "alternativeText":null,
                        "caption":null,
                        "width":1080,
                        "height":1080,
                        "formats":{
                            "large":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/large_Frame_267_83af5ac8d3.png",
                                "hash":"large_Frame_267_83af5ac8d3",
                                "mime":"image/png",
                                "name":"large_Frame 267.png",
                                "path":null,
                                "size":31.47,
                                "width":1000,
                                "height":1000
                            },
                            "small":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_Frame_267_83af5ac8d3.png",
                                "hash":"small_Frame_267_83af5ac8d3",
                                "mime":"image/png",
                                "name":"small_Frame 267.png",
                                "path":null,
                                "size":12.68,
                                "width":500,
                                "height":500
                            },
                            "medium":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/medium_Frame_267_83af5ac8d3.png",
                                "hash":"medium_Frame_267_83af5ac8d3",
                                "mime":"image/png",
                                "name":"medium_Frame 267.png",
                                "path":null,
                                "size":21.53,
                                "width":750,
                                "height":750
                            },
                            "thumbnail":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_Frame_267_83af5ac8d3.png",
                                "hash":"thumbnail_Frame_267_83af5ac8d3",
                                "mime":"image/png",
                                "name":"thumbnail_Frame 267.png",
                                "path":null,
                                "size":5.36,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"Frame_267_83af5ac8d3",
                        "ext":".png",
                        "mime":"image/png",
                        "size":8.22,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-08-16T08:30:36.790Z",
                        "updatedAt":"2023-08-16T08:30:36.790Z"
                    }
                }
            }
        }
    },
    {
        "id":8,
        "attributes":{
            "title":"Community",
            "description":"Telegram-native toolset for communities and apps.",
            "url":"https://t.me/community_bot/",
            "path":"community",
            "createdAt":"2023-08-07T17:38:48.659Z",
            "updatedAt":"2023-11-14T11:05:19.201Z",
            "publishedAt":"2023-08-07T17:38:50.108Z",
            "locale":"en",
            "editors_choice":true,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":62,
                    "attributes":{
                        "name":"5F12A789-6895-4B3B-A543-EAD5E3B1B54C.png",
                        "alternativeText":null,
                        "caption":null,
                        "width":1260,
                        "height":1260,
                        "formats":{
                            "large":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/large_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8.png",
                                "hash":"large_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8",
                                "mime":"image/png",
                                "name":"large_5F12A789-6895-4B3B-A543-EAD5E3B1B54C.png",
                                "path":null,
                                "size":364.19,
                                "width":1000,
                                "height":1000
                            },
                            "small":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8.png",
                                "hash":"small_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8",
                                "mime":"image/png",
                                "name":"small_5F12A789-6895-4B3B-A543-EAD5E3B1B54C.png",
                                "path":null,
                                "size":73.21,
                                "width":500,
                                "height":500
                            },
                            "medium":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/medium_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8.png",
                                "hash":"medium_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8",
                                "mime":"image/png",
                                "name":"medium_5F12A789-6895-4B3B-A543-EAD5E3B1B54C.png",
                                "path":null,
                                "size":190.91,
                                "width":750,
                                "height":750
                            },
                            "thumbnail":{
                                "ext":".png",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8.png",
                                "hash":"thumbnail_5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8",
                                "mime":"image/png",
                                "name":"thumbnail_5F12A789-6895-4B3B-A543-EAD5E3B1B54C.png",
                                "path":null,
                                "size":9.91,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"5_F12_A789_6895_4_B3_B_A543_EAD_5_E3_B1_B54_C_9c93934ff8",
                        "ext":".png",
                        "mime":"image/png",
                        "size":64.7,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-08-17T14:18:01.934Z",
                        "updatedAt":"2023-08-17T14:18:01.934Z"
                    }
                }
            }
        }
    },
    {
        "id":151,
        "attributes":{
            "title":"MemoCard",
            "description":"Improve your memory with spaced repetition.",
            "url":"https://t.me/memo_card_bot",
            "path":"memo-card",
            "createdAt":"2023-11-17T16:41:44.030Z",
            "updatedAt":"2023-11-17T18:49:52.691Z",
            "publishedAt":"2023-11-17T18:49:52.686Z",
            "locale":"en",
            "editors_choice":null,
            "webapp_url":null,
            "community_url":null,
            "icon":{
                "data":{
                    "id":303,
                    "attributes":{
                        "name":"2023-11-17 19.40.54.jpg",
                        "alternativeText":null,
                        "caption":null,
                        "width":640,
                        "height":640,
                        "formats":{
                            "small":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/small_2023_11_17_19_40_54_bc82615c9f.jpg",
                                "hash":"small_2023_11_17_19_40_54_bc82615c9f",
                                "mime":"image/jpeg",
                                "name":"small_2023-11-17 19.40.54.jpg",
                                "path":null,
                                "size":13.3,
                                "width":500,
                                "height":500
                            },
                            "thumbnail":{
                                "ext":".jpg",
                                "url":"https://strapi-images-data.s3.eu-central-1.amazonaws.com/thumbnail_2023_11_17_19_40_54_bc82615c9f.jpg",
                                "hash":"thumbnail_2023_11_17_19_40_54_bc82615c9f",
                                "mime":"image/jpeg",
                                "name":"thumbnail_2023-11-17 19.40.54.jpg",
                                "path":null,
                                "size":3.04,
                                "width":156,
                                "height":156
                            }
                        },
                        "hash":"2023_11_17_19_40_54_bc82615c9f",
                        "ext":".jpg",
                        "mime":"image/jpeg",
                        "size":18.01,
                        "url":"",
                        "previewUrl":null,
                        "provider":"aws-s3",
                        "provider_metadata":null,
                        "createdAt":"2023-11-17T16:41:01.973Z",
                        "updatedAt":"2023-11-17T16:41:01.973Z"
                    }
                }
            }
        }
    }
]
