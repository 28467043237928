import { useTranslation } from 'react-i18next';
import { AddIcon } from '../../svg/AddIcon';
import { DevelopersIcon } from '../../svg/DevelopersIcon';
import { TelegramIcon } from '../../svg/TelegramIcon';
import { openTelegramLink } from '../../utils/common';
import {
    FOR_DEVELOPERS_URL,
    SUBMIT_APP_URL,
    TELEGRAM_CHANNEL_URL,
} from '../../utils/constatns';
import { Button } from '../Button/Button';
import { Caption, SubHeadline } from '../Typography/Typography';
import styles from './style.module.scss';

export function Footer() {
    const { t } = useTranslation();

    return (
        <footer className={styles.root}>
            <a href={TELEGRAM_CHANNEL_URL} target={"_blank"} onClick={() => openTelegramLink(TELEGRAM_CHANNEL_URL)}>
                <Button type={'tertiary'} className={styles.linksButton}>
                    <TelegramIcon />
                    <SubHeadline
                        level={1}
                        weight={'semi-bold'}
                        className={styles.subheadline}
                    >
                        {t("joinChannel")}
                    </SubHeadline>
                </Button>
            </a>
            <a href={SUBMIT_APP_URL} target={"_blank"} onClick={() => openTelegramLink(SUBMIT_APP_URL)}>
                <Button type={'tertiary'} className={styles.linksButton}>
                    <AddIcon />
                    <SubHeadline
                        level={1}
                        weight={'semi-bold'}
                        className={styles.subheadline}
                    >
                        {t("submit")}
                    </SubHeadline>
                </Button>
            </a>
            <a href={FOR_DEVELOPERS_URL} target={"_blank"} onClick={() => openTelegramLink(FOR_DEVELOPERS_URL)}>
                <Button type={'tertiary'} className={styles.linksButton}>
                    <DevelopersIcon />
                    <SubHeadline
                        level={1}
                        weight={'semi-bold'}
                        className={styles.subheadline}
                    >
                        {t("developersSign")}
                    </SubHeadline>
                </Button>
            </a>
            <div className={styles.copyright}>
                <Caption level={1} weight={"regular"}>
                    {t("legal")}
                    <br />
                    {t("legalOrigin")}
                </Caption>
            </div>
        </footer>
    );
}
