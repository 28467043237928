import React from 'react';
import {
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Layout from './app/_components/Layout';
import IndexPage from './app/pages/index/page';
import RecentPage from './app/pages/recent/page';
import CategoryPage from './app/pages/category/page';
import ApplicationPage from './app/pages/application/page';

import Page from './app/pages/notFound/page';

import 'swiper/css';
import './styles/globals.scss';
import { SettingsPage } from './app/pages/settings/page';
import { TWA_ROOT_PATH } from './utils/constatns';

const router = createBrowserRouter([
    {
        id: "root",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <IndexPage />,
            },
            {
                path: "/:category",
                element: <CategoryPage />,
            },
            {
                path: "/applications/:application",
                element: <ApplicationPage />,
            },
            {
                path: "/recent",
                element: <RecentPage />,
            },
            {
                path: "/settings",
                element: <SettingsPage />
            },
            {
                path: "*",
                element: <Page />,
            },
        ]
    },
], {
    basename: TWA_ROOT_PATH
});

function App() {
    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
