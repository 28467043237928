import { SVGProps } from 'react'

export const ShieldIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" {...props}>
            <path
                fillRule="evenodd" clipRule="evenodd"
                d="M10.1207 24.0337C10.2652 24.0739 10.3896 24.0939 10.494 24.0939C10.6064 24.0939 10.7348 24.0739 10.8793 24.0337C11.0238 24.0016 11.1763 23.9454 11.3368 23.8651C13.0627 22.9741 14.5116 22.1634 15.6836 21.4329C16.8556 20.7104 17.7948 19.992 18.5012 19.2775C19.2076 18.5631 19.7173 17.7965 20.0304 16.9777C20.3435 16.1509 20.5 15.1997 20.5 14.124V5.16556C20.5 4.45916 20.3595 3.9414 20.0786 3.61228C19.8056 3.28316 19.3722 2.99819 18.7781 2.75738C18.5132 2.64499 18.144 2.5005 17.6704 2.3239C17.2048 2.1473 16.683 1.95866 16.1051 1.75798C15.5351 1.54927 14.9612 1.34056 14.3832 1.13185C13.8133 0.923139 13.2955 0.738511 12.8299 0.577965C12.3643 0.417419 12.0031 0.292996 11.7462 0.204696C11.5456 0.148505 11.3368 0.100341 11.1201 0.0602047C10.9114 0.0200682 10.7027 0 10.494 0C10.2933 0 10.0886 0.0200682 9.87989 0.0602047C9.67118 0.100341 9.46649 0.148505 9.2658 0.204696C9.00893 0.292996 8.64369 0.417419 8.17008 0.577965C7.7045 0.738511 7.18272 0.923139 6.60476 1.13185C6.03482 1.33253 5.46087 1.53723 4.8829 1.74594C4.31296 1.95465 3.79119 2.15131 3.31758 2.33594C2.852 2.51254 2.48676 2.65302 2.22185 2.75738C1.63586 2.99819 1.20239 3.28316 0.921433 3.61228C0.640478 3.9414 0.5 4.45916 0.5 5.16556V14.124C0.5 15.1997 0.660546 16.1429 0.981638 16.9536C1.30273 17.7644 1.82049 18.519 2.53492 19.2173C3.24935 19.9077 4.19255 20.6181 5.36454 21.3486C6.53652 22.0871 7.9694 22.9259 9.66315 23.8651C9.8237 23.9454 9.97622 24.0016 10.1207 24.0337ZM16.4353 9.56923C16.9039 9.1006 16.9039 8.3408 16.4353 7.87218C15.9666 7.40355 15.2068 7.40355 14.7382 7.87218L9.04128 13.5691L6.61708 11.1449C6.14845 10.6763 5.38866 10.6763 4.92003 11.1449C4.4514 11.6135 4.4514 12.3733 4.92003 12.842L8.19275 16.1147C8.66138 16.5833 9.42118 16.5833 9.88981 16.1147L16.4353 9.56923Z"
                fill="currentColor"
            />
        </svg>
    )
}
