import qs from 'qs';
import { TWAApplication } from '../../../types/entities';
import { PAGE_SIZE } from './CompactAppList';

interface IGetKeyParams {
    pageIndex: number,
    activeCategory?: string,
    pageSize?: number,
    skip?: TWAApplication | null | undefined,
    locale?: string,
}

export function getKey(params: IGetKeyParams) {
    const {
        pageIndex,
        activeCategory,
        pageSize,
        skip,
        locale,
    } = params;

    const query = {
        pagination: {
            pageSize: pageSize || PAGE_SIZE,
            page: pageIndex + 1,
        },
        sort: ['editors_choice:asc', 'createdAt:desc'],
        populate: [
            'icon',
            'categories',
            'poster',
            'platforms',
        ],
        filters: {},
        // locale,
    }

    if (activeCategory) {
        query.filters = {
            categories: {
                title: {
                    $eq: activeCategory,
                },
            },
            title: {
                $not: {
                    $eq: 'TWA',
                }
            }
        }
    }

    try {
        const ecosystemKeyName = window?.ecosystem

        if (ecosystemKeyName) {
            query.filters = {
                ...query.filters,
                ecosystem: {
                    $eq: ecosystemKeyName
                }
            }
        }
    } catch (e) {
    }

    query.filters = {
        ...query.filters,
        platforms: {
            name: {
                $eq: window.Telegram.WebApp.platform,
            }
        },
    }

    if (skip) {
        query.filters = {
            ...query.filters,
            id: {
                $ne: skip.id
            }
        }
    }

    return `applications?${qs.stringify(query)}`;
}
