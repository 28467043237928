import { motion } from 'framer-motion';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';
import React, { useCallback } from 'react';
import { getCategoryForAnalytics } from '../../utils/common'

import { CompactApplicationCard } from '../CompactApplicationCard/CompactApplicationCard';
import { LoadMoreButton } from './LoadMoreButton/LoadMoreButton';
import { getKey } from './utils';
import { TWAApplication } from '../../../types/entities';
import { EmptySearch } from '../EmptySearch/EmptySearch';
import { MOCK_SHIMMERS_DATA } from '../../utils/mocks/shimmers';
import { useOpenAppCommand } from '../../hooks/app/useOpenAppCommand';
import { getApplicationsWithPagination } from '../../utils/api/requests';
import { useDataContext } from '../../dataSource/DataContext/DataContext';

import styles from './styles.module.scss';

export const PAGE_SIZE = 20;
export const PREFETCH_SIZE = 7;

interface ITWAAppListProps extends React.HTMLAttributes<HTMLDivElement> {
    skip?: TWAApplication | null | undefined,
    limit?: number,
    step?: number,
    pages?: number,
    category?: string,
    isLoading?: boolean,
    applications?: Array<TWAApplication>,
}

export const CompactAppList: React.FC<ITWAAppListProps> = React.memo((props) => {
    const { activeCategory, appsRanking } = useDataContext();
    const { createOpenAppCommand } = useOpenAppCommand();
    const { i18n } = useTranslation();
    const {
        data,
        size,
        setSize,
        isLoading,
        isValidating,
    } = useSWRInfinite(
        (pageIndex) => getKey({
            pageIndex,
            activeCategory: props.category || activeCategory?.attributes?.title,
            pageSize: props.limit,
            skip: props.skip,
            locale: i18n.language,
        }),
        getApplicationsWithPagination,
        {
            parallel: true,
            revalidateAll: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        },
    );

    // @ts-ignore
    const pages = props.pages ?? data?.[0]?.meta?.pagination?.pageCount ?? 0;
    const moreDataExists = size < pages;
    const isLoadingMore =
        isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

    const loadMore = useCallback(() => {
        if (!isValidating) {
            if (moreDataExists) {
                setSize(size + 1);
            }
        }
    }, [size, isValidating, setSize, moreDataExists])

    if (props.isLoading || isLoading || !appsRanking?.length) {
        const shimmersToRender = props.limit
            ? MOCK_SHIMMERS_DATA.slice(0, props.limit)
            : MOCK_SHIMMERS_DATA;

        return (
            <div className={classNames(styles.root, props.className)}>
                {
                    shimmersToRender.map((item, index) => {
                        return (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0.4 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.30}}
                            >
                                <CompactApplicationCard
                                    key={index}
                                    loading={true}
                                />
                            </motion.div>
                        )
                    })
                }
            </div>
        )
    }

    if (!data?.length) {
        return (
            <div className={classNames(styles.root, styles.empty, props.className)}>
                <EmptySearch withoutDescription/>
            </div>
        )
    }

    const dataToRender = props.applications
        ? [{ data: props.applications }]
        : data;

    return (
        <div className={classNames(styles.root, styles.twa, props.className)}>
            {
                dataToRender.map((page, index) => {
                    const isLastPage = index === dataToRender.length - 1;
                    const [
                        applicationsToRender,
                        preloaderApplications,
                    ] = getApplicationsToRender(page?.data, props.limit || PAGE_SIZE, props.step);

                    return (
                        <React.Fragment key={index}>
                            {
                                applicationsToRender?.map((item, index) => {
                                    return (
                                        <CompactApplicationCard
                                            key={item.id}
                                            application={item}
                                            onButtonClick={() => {
                                                createOpenAppCommand(item, getCategoryForAnalytics(props));
                                            }}
                                        />
                                    )
                                })
                            }
                            {
                                preloaderApplications?.length > 0 && (
                                    <LoadMoreButton
                                        key={`loadMore-${index}_${props.category}`}
                                        itemKey={`loadMore-${index}_${props.category}`}
                                        loadMore={loadMore}
                                        applications={preloaderApplications}
                                        expanded={!(moreDataExists && isLastPage)}
                                        isLoadingMore={isLoadingMore}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
}, (prev, next) => prev.isLoading === next.isLoading);

CompactAppList.displayName = 'CompactAppList';

function getApplicationsToRender(page: null | undefined | TWAApplication[], limit: number, step: number = PREFETCH_SIZE) {
    if (!page) {
        return [];
    }

    if (page.length < limit) {
        return [page];
    }

    const start = page.slice(0, page.length - step);
    const end = page.slice(page.length - step, page.length);

    if (end.length <= step) {
        return [start, end];
    }

    return [page];

}
