import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Feature } from '../../../../types/Blocks';
import { TelegramWebApps } from '../../../../types/common';
import { TWAApplication } from '../../../../types/entities';
import { useTWAPlatform } from '../../../hooks';
import { useOpenAppCommand } from '../../../hooks/app/useOpenAppCommand';
import system from '../../../services/System'
import { Button } from '../../../uilib/Button/Button';
import { ProgressiveImage } from '../../../uilib/ProgressiveImage/ProgressiveImage';
import { Ripple } from '../../../uilib/Ripple/Ripple';
import { Body, Caption, Heading, SubHeadline } from '../../../uilib/Typography/Typography';
import { openTelegramLink } from '../../../utils/common';
import { TWA_APPLICATIONS_ROOT_PATH } from '../../../utils/constatns';
import styles from './styles.module.scss';

interface IBannerBlockProps {
    feature: Feature,
}

export const FeaturingBlock: React.FC<IBannerBlockProps> = (props) => {
    const { feature } = props;
    const { createOpenAppCommand } = useOpenAppCommand();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const platform = useTWAPlatform();

    const style = {
        "--background-gradient-color": props?.feature?.banner?.background,
    } as React.CSSProperties;

    const tags = props.feature?.banner?.tags?.length
        ? props.feature?.banner?.tags?.split('|')
        : [];

    if (!assertPlatformIsValid(platform, props.feature?.application?.data)) {
        return null;
    }

    const bodyIsEmpty = assertBodyIsEmpty(feature, tags);

    return (
        <div
            className={styles.root}
            style={style}
        >
            <div className={classNames(styles.container, bodyIsEmpty && styles.containerEmpty)}>
                {
                    tags?.length > 0 && (
                        <div className={styles.header}>
                            {
                                tags?.filter(Boolean).slice(0, 6).map((tag, index) => {
                                    return (
                                        <div className={styles.tag} key={`${tag}_${index}}`}>
                                            <Caption
                                                level={2}
                                                weight={'regular'}
                                                className={classNames(styles.text, styles.ellipsis_1)}
                                                caps
                                            >
                                                {tag}
                                            </Caption>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
                {
                    props.feature?.banner?.title && props.feature?.banner?.description && (
                        <div className={styles.body}>
                            <Heading
                                type={'h2'}
                                weight={'bold'}
                                className={classNames(styles.text, styles.ellipsis_2)}
                            >
                                {props.feature?.banner?.title}
                            </Heading>
                            <SubHeadline
                                level={1}
                                weight={'regular'}
                                className={classNames(styles.text, styles.ellipsis_3)}
                            >
                                {props.feature?.banner?.description}
                            </SubHeadline>
                        </div>
                    )
                }
                {
                    props.feature && props.feature?.entity?.label && props.feature?.entity?.url && (
                        <div className={styles.entityFooter}>
                            <Ripple className={styles.entityButtonWrapper}>
                                <button
                                    className={styles.entityButton}
                                    onClick={() => openTelegramLink(props.feature.entity?.url)}
                                >
                                    <Body
                                        weight={'semi-bold'}
                                        className={classNames(styles.entityButtonLabel, styles.ellipsis_1)}
                                    >
                                        {props.feature.entity?.label}
                                    </Body>
                                </button>
                            </Ripple>
                        </div>
                    )
                }
                {
                    props.feature?.application?.data && (
                        <Ripple className={styles.footer} onClick={() => {
                            system.delayAction(() => {
                                const path = `${TWA_APPLICATIONS_ROOT_PATH}/${props.feature?.application?.data.attributes.path}`;

                                navigate(path)
                            })
                        }}>
                            <ProgressiveImage
                                src={props.feature?.application.data?.attributes.icon.data?.attributes.url}
                                width={48}
                                height={48}
                                alt={props.feature?.application.data?.attributes.title || ""}
                                className={styles.entity__image}
                            />
                            <div className={styles.entity__body}>
                                <Body
                                    weight={'medium'}
                                    className={classNames(styles.text, styles.ellipsis_1)}
                                >
                                    {props.feature?.application.data?.attributes.title}
                                </Body>
                                <SubHeadline
                                    level={1}
                                    weight={'regular'}
                                    className={classNames(styles.text, styles.ellipsis_1)}
                                >
                                    {props.feature?.application.data?.attributes.description}
                                </SubHeadline>
                            </div>
                            <Button
                                type={'context'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    createOpenAppCommand(
                                        props.feature?.application?.data,
                                        "FeatureBlock",
                                    )
                                }}
                            >
                                <SubHeadline
                                    level={1}
                                    weight={'semi-bold'}
                                    caps
                                >
                                    {t("open")}
                                </SubHeadline>
                            </Button>
                        </Ripple>
                    )
                }
            </div>
        </div>
    );
}

function assertPlatformIsValid(platform: TelegramWebApps.Platform, application?: TWAApplication) {
    if (!application) {
        return true;
    }

    return application?.attributes?.platforms?.data?.some((p) => p.attributes.name === platform);
}

function assertBodyIsEmpty(feature: Feature, tags: string[]) {
    return tags.length === 0 && (feature?.banner?.title === null || feature?.banner?.description === null);
}
