import React from 'react';

import { TWAAppList } from '../../../uilib/AppList/TWAAppList';
import { useApplicationContext } from '../../../dataSource/ApplicationContext';
import { TWARecommendationsHeader } from './TWARecommendationsHeader';
import { getCategoryToRecommend } from './utils';

import styles from './styles.module.scss';

export function TWARecommendations() {
    const {
        isLoading,
        application,
    } = useApplicationContext();

    const categoryToRecommend = getCategoryToRecommend(application);

    return (
        <div className={styles.root}>
            <TWARecommendationsHeader
                category={categoryToRecommend}
            />
            <TWAAppList
                className={styles.list}
                isLoading={isLoading}
                skip={application}
                pages={0}
                limit={6}
                category={categoryToRecommend?.attributes?.title || "All"}
            />
        </div>
    )
}
