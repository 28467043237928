import classNames from 'classnames'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAlertBehaviour } from '../../../hooks/useAlertBehaviour';
import { Alert } from '../../../uilib/Alert/Alert';

import { Button } from '../../../uilib/Button/Button';
import { TWACategory } from '../../../../types/entities';
import { TWAAppList } from '../../../uilib/AppList/TWAAppList';
import { CompactAppList } from '../../../uilib/CompactAppList/CompactAppList';
import { Body, Heading } from '../../../uilib/Typography/Typography';

import styles from './styles.module.scss';

interface ICategoryBlockProps {
    category: TWACategory;
    withSeparator: boolean;
    compressedSeparator: boolean;
    analytics_prefix?: string;
}

export const CategoryBlock: React.FC<ICategoryBlockProps> = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isVisible, handleClose, isLoading } = useAlertBehaviour(props);

    return (
        <div className={classNames(styles.root, props.compressedSeparator && styles.compressedSeparatorOffset)}>
            {
                props.withSeparator && (
                    <div className={styles.separatorWrapper}>
                        <div className={styles.separator}></div>
                    </div>
                )
            }
            <div className={styles.header}>
                <Heading
                    type={'h4'}
                    weight={'bold'}
                    className={styles.heading}
                >
                    {props.category.attributes.title}
                </Heading>
                {
                    props.category.attributes.path && (
                        <Button
                            type={'tertiary'}
                            className={styles.button}
                            onClick={(e) => {
                                e.preventDefault()

                                navigate(`/${props.category.attributes.path}`)
                            }}
                        >
                            <Body weight={'regular'} className={styles.label}>
                                {t("seeAll")}
                            </Body>
                        </Button>
                    )
                }
            </div>
            {
                isVisible && (
                    <Alert
                        alert={props.category?.attributes?.alert?.data}
                        onClose={handleClose}
                    />
                )
            }
            <div className={classNames({
                [styles.body]: true,
                [styles.compact]: props.category.attributes.compact,
            })}>
                {
                    props.category.attributes.compact
                        ? (
                            <CompactAppList
                                isLoading={isLoading}
                                limit={7}
                                step={2}
                                prefix={[props.analytics_prefix, 'Compact'].join('-')}
                                category={props.category.attributes.title}
                            />
                        )
                        : (
                            <>
                                <div className={styles.scrollItem}/>
                                <div className={styles.scrollItem}>
                                    <TWAAppList
                                        isLoading={isLoading}
                                        className={styles.list}
                                        pages={0}
                                        limit={6}
                                        prefix={props.analytics_prefix}
                                        category={props.category.attributes.title}
                                    />
                                </div>
                                <div className={styles.scrollItem}/>
                            </>
                        )
                }
            </div>
        </div>
    )
}
