import { SVGProps } from 'react';

export function DownIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.99463 0.0249023C5.17008 0.0249023 5.31152 0.0804036 5.41895 0.191406C5.52995 0.302409 5.58545 0.445638 5.58545 0.621094V7.28662L5.521 9.0376L5.16113 8.86035L6.95508 6.86768L8.17432 5.66455C8.22803 5.61084 8.29069 5.56966 8.3623 5.54102C8.43392 5.50879 8.5109 5.49268 8.59326 5.49268C8.75439 5.49268 8.88867 5.54639 8.99609 5.65381C9.10352 5.76123 9.15723 5.8973 9.15723 6.06201C9.15723 6.22314 9.09635 6.36637 8.97461 6.4917L5.43506 10.0366C5.37777 10.0975 5.30973 10.144 5.23096 10.1763C5.15576 10.2085 5.07699 10.2246 4.99463 10.2246C4.91585 10.2246 4.83887 10.2085 4.76367 10.1763C4.68848 10.144 4.62044 10.0975 4.55957 10.0366L1.02002 6.4917C0.898275 6.36637 0.837402 6.22314 0.837402 6.06201C0.837402 5.8973 0.891113 5.76123 0.998535 5.65381C1.10596 5.54639 1.24023 5.49268 1.40137 5.49268C1.48372 5.49268 1.56071 5.50879 1.63232 5.54102C1.70394 5.56966 1.7666 5.61084 1.82031 5.66455L3.03418 6.86768L4.82812 8.86035L4.47363 9.0376L4.40918 7.28662V0.621094C4.40918 0.445638 4.46289 0.302409 4.57031 0.191406C4.68132 0.0804036 4.82275 0.0249023 4.99463 0.0249023Z"
                fill="currentColor"
            />
        </svg>
    )
}
