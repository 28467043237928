import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Done } from '../../../../../svg/Done';
import { Ripple } from '../../../../../uilib/Ripple/Ripple';
import { Body, SubHeadline } from '../../../../../uilib/Typography/Typography';
import { IFormValues } from '../../page'
import styles from './page.module.scss';

interface ISelectProps {
    value: string,
    title: string,
    description: string,
}

export const Select = React.forwardRef<
    HTMLSelectElement,
    ISelectProps & ReturnType<UseFormRegister<IFormValues>>
>((props, ref) => {
    return (
        <Ripple className={styles.root}>
            <label className={styles.label}>
                <input
                    type={"radio"}
                    value={props.value}
                    // @ts-ignore
                    ref={ref}
                    onChange={props.onChange}
                    name={props.name}
                />
                <div className={styles.checkbox}>
                    <Done className={styles.checkboxIcon}/>
                </div>
                <div className={styles.row}>
                    <Body weight={"regular"}>
                        {props.title}
                    </Body>
                    <SubHeadline level={2} weight={"regular"}>
                        {props.description}
                    </SubHeadline>
                </div>
            </label>
        </Ripple>
    );
})
