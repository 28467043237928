import { motion } from 'framer-motion';
import React from 'react';
import { Spinner } from '../../Spinner/Spinner';
import styles from './styles.module.scss';

export const AnimatedSpinner: React.FC = () => {
    return (
        <motion.span
            initial={{scale: 0.5}}
            animate={{scale: 1}}
            transition={{
                type: 'spring',
                damping: 20,
                stiffness: 700,
                duration: 0.2,
            }}
            className={styles.collider__spinner__root}
        >
            <Spinner className={styles.collider__spinner}/>
        </motion.span>
    )
}
