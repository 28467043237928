import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { TWAApplication, TWALaunchHistoryItem } from '../../../../types/entities';

import { useTWAUser } from '../../../hooks';
import { Heading } from '../../../uilib/Typography/Typography';
import { getRecentlyLaunchedFromApi } from '../../../utils/api/requests';
import { RecentlyLaunchedPlaceholder } from './Placeholder';
import { RecentlyOpened } from './RecentlyLaucnhedApp';
import styles from './styles.module.scss';
import { resetRecentlyLaunchedContainerHeight } from './utils'

export const RecentlyLaunched = () => {
    const { t } = useTranslation();
    const twaUser = useTWAUser();
    const { data, error, isLoading} = useSWR(
        twaUser,
        getRecentlyLaunchedFromApi,
    );

    if (isLoading) {
        return <RecentlyLaunchedPlaceholder />;
    }

    if (error) {
        resetRecentlyLaunchedContainerHeight();

        return null;
    }

    if (data?.data?.applications?.length === 0 || data?.data?.history === 0) {
        resetRecentlyLaunchedContainerHeight();

        return null;
    }

    const apps: Array<TWAApplication> = data?.data?.applications ?? [];
    const history: Array<TWALaunchHistoryItem> = data?.data?.history ?? [];
    const orderedApps = history
        .map((item) => apps.find((app) => app.id === Number(item.app_id)))
        .filter(Boolean);

    return (
        <div
            className={styles.root}
            id={"recently__launched__container"}
        >
            <Heading
                type={"h4"}
                weight={"bold"}
                className={styles.recentlyLaunchedHeader}
            >
                {t("recentlyOpened")}
            </Heading>
            <div className={styles.appContainer}>
                {
                    orderedApps.map((app) => {
                        return app && (
                            <RecentlyOpened
                                className={styles.recentlyLaunchedItem}
                                key={app.id}
                                app={app}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
