import numeral from 'numeral'
import { IJettonInfoResponse } from '../../../utils/api/graphql/getJettonInfo'

export function getMarketDataFromQuery (queryResponse?: IJettonInfoResponse) {
    if (!queryResponse) {
        return {
            priceFeed: [],
            last24hPrice: 0,
            last24hActualDate: "",
            last7dPrice: 0,
            last7dActualDate: "",
            lastActualPrice: 0,
            lastActualDate: "",
        };
    }

    const lastActualPrice = queryResponse.marketData_0[0].price;
    const lastActualDate = queryResponse.marketData_0[0].build_time;

    let last24hPrice = 0;
    let last24hActualDate = "";

    let last7dPrice = 0;
    let last7dActualDate = "";

    const {
        marketData_0,
        marketData_1,
        marketData_2,
        marketData_3,
    } = queryResponse

    const priceFeed =  [
        ...marketData_0,
        ...marketData_1,
        ...marketData_2,
        ...marketData_3,
    ].sort(
        (a, b) => {
            const dateDiff = (new Date(lastActualDate).getTime() - new Date(a.build_time).getTime()) / (3600 * 1000 * 24);

            if (dateDiff <= 1) {
                last24hPrice = a.price;
                last24hActualDate = a.build_time;
            }

            if (dateDiff <= 7) {
                last7dPrice = a.price;
                last7dActualDate = a.build_time;
            }

            return new Date(a.build_time).getTime() - new Date(b.build_time).getTime()
        }
    )

    return {
        priceFeed,
        lastActualPrice,
        lastActualDate,
        last24hPrice,
        last24hActualDate,
        last7dPrice,
        last7dActualDate,
    }
}

export function calcPercentage(x: number, y: number, fixed = 2) {
    const percent = ((x - y) / y) * 100;

    if (!isNaN(percent)) {
        const percentage = Number(percent);

        const value = percentage > 0 ? `+${percentage.toFixed(fixed)}%` : `${percentage.toFixed(fixed)}%`;

        return {
            value,
            positive: percentage > 0
        };
    } else {
        return {
            value: null,
            positive: null
        };
    }
}


export function formatPrice(price: number, toncoinPrice: Record<string, any>) {
    if (!toncoinPrice?.price)  {
        return "$-,--.--"
    }

    return numeral(price * Number(toncoinPrice?.price)).format('$0,0.00')
}
