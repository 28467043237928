import React from 'react';

import { useDataContext } from '../../../dataSource/DataContext/DataContext';
import { useScrollRestoration } from '../../../hooks/useScrollRestoration';
import { TWAFooter } from '../../../uilib/Footer/TWAFooter';
import { TWAHeader } from '../../../uilib/Header/TWAHeader';
import { TWATabs } from '../../../uilib/Tabs/TWATabs';
import { Block } from '../../_blocks/Block/Block';

import styles from '../page.module.scss';

export default function IndexPage() {
    const { mainPageBlockConfig } = useDataContext();
    const { ref } = useScrollRestoration('indexPage', {
        debounceTime: 200,
    });

    return (
        <main ref={ref} className={styles.main}>
            <TWAHeader/>
            <TWATabs/>
            {
                mainPageBlockConfig?.map((block, index) => {
                    return (
                        <Block
                            key={block.id}
                            blockData={block}
                            index={index}
                            config={mainPageBlockConfig}
                        />
                    )
                })
            }
            <div className={styles.footerWrapper}>
                <TWAFooter/>
            </div>
        </main>
    )
}
