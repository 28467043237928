import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRInfinite from 'swr/infinite'
import { TWAApplication } from '../../../types/entities'
import { useDataContext } from '../../dataSource/DataContext/DataContext'

import { reorderApps } from '../../dataSource/DataContext/utils'
import { useOpenAppCommand } from '../../hooks/app/useOpenAppCommand'
import { getApplicationsWithPagination } from '../../utils/api/requests'
import { getCategoryForAnalytics } from '../../utils/common'
import { isNewApp } from '../../utils/dates'
import { MOCK_SHIMMERS_DATA } from '../../utils/mocks/shimmers'
import { ApplicationCard } from '../ApplicationCard/ApplicationCard'
import { TWAApplicationCard } from '../ApplicationCard/TWAApplicationCard'
import { EmptySearch } from '../EmptySearch/EmptySearch'

import styles from './styles.module.scss'
import { getKey } from './utils'
import { VisibilityHandler } from './VisibilityHandler'

export const PAGE_SIZE = 14;

interface ITWAAppListProps extends React.HTMLAttributes<HTMLDivElement> {
    skip?: TWAApplication | null | undefined,
    limit?: number,
    pages?: number,
    category?: string,
    blockId?: string,
    isLoading?: boolean,
    applications?: Array<TWAApplication>,
}

export const TWAAppList: React.FC<ITWAAppListProps> = React.memo((props) => {
    const { activeCategory, appsRanking } = useDataContext();
    const { createOpenAppCommand } = useOpenAppCommand();
    const { i18n } = useTranslation();
    const {
        data,
        size,
        setSize,
        isLoading,
        isValidating,
    } = useSWRInfinite(
        (pageIndex) => getKey({
            pageIndex,
            activeCategory: props.category || activeCategory?.attributes?.title,
            pageSize: props.limit,
            skip: props.skip,
            locale: i18n.language,
        }),
        getApplicationsWithPagination,
        {
            parallel: true,
            revalidateAll: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        },
    );

    // @ts-ignore
    const pages = props.pages ?? data?.[0]?.meta?.pagination?.pageCount ?? 0;
    const moreDataExists = size <= pages;

    const handleScrollEnd = useCallback((isVisible: boolean) => {
        if (isVisible && !isValidating) {

            if (moreDataExists) {
                setSize(size + 1);
            }
        }
    }, [size, isValidating, setSize, moreDataExists])

    if (props.isLoading || isLoading || !appsRanking?.length) {
        const shimmersToRender = props.limit
            ? MOCK_SHIMMERS_DATA.slice(0, props.limit)
            : MOCK_SHIMMERS_DATA;

        return (
            <div className={classNames(styles.root, styles.twa, props.className)}>
                {
                    shimmersToRender.map((item, index) => {
                        return (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0.4 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.30}}
                            >
                                <ApplicationCard
                                    key={index}
                                    {...item}
                                />
                            </motion.div>
                        )
                    })
                }
            </div>
        )
    }

    if (!data?.length) {
        return (
            <div className={classNames(styles.root, styles.empty, props.className)}>
                <EmptySearch withoutDescription/>
            </div>
        )
    }

    const dataToRender = props.applications
        ? [{ data: props.applications }]
        : data;

    return (
        <>
            <div className={classNames(styles.root, styles.twa, props.className)}>
                {
                    dataToRender.map((page, index) => {
                        return reorderApps(page?.data, appsRanking)
                            .map((item, ) => {
                                const { attributes } = item;

                                return (
                                    <TWAApplicationCard
                                        key={item.id}
                                        title={attributes.title}
                                        isNew={isNewApp(attributes.createdAt)}
                                        description={attributes.description}
                                        editorsChoice={Boolean(attributes.editors_choice)}
                                        path={attributes.path}
                                        url={attributes.url}
                                        onButtonClick={() => {
                                            createOpenAppCommand(item, getCategoryForAnalytics(props));
                                        }}
                                        img={{
                                            src: attributes?.icon?.data?.attributes.url ?? "",
                                            name: attributes?.icon?.data?.attributes.name ?? "",
                                        }}
                                    />
                                )
                            })
                    })
                }
                {
                    moreDataExists && (
                        <VisibilityHandler handleVisibilityChange={handleScrollEnd} />
                    )
                }
            </div>
        </>
    );
}, (prev, next) => prev.isLoading === next.isLoading);

TWAAppList.displayName = 'TWAAppList';

