import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss';

import { Footnote } from '../../../../../uilib/Typography/Typography';

export const Header: React.FC<PropsWithChildren> = (props) => {
    return (
        <div className={styles.root}>
            <Footnote caps weight={"semi-bold"}>
                {props.children}
            </Footnote>
        </div>
    )
}
