import { useEffect, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { getMainPageBlockConfig, getNavigationConfig } from '../../utils/api/config';
import { getCategories, getConfig, getRankings } from '../../utils/api/requests';

import { ejectLoader } from '../../utils/ui';

export const useMiniAppInitAction = () => {
    const config = useSWRImmutable('config', getConfig);
    const rankings = useSWRImmutable('rankings', getRankings);
    const categories = useSWRImmutable('categories', getCategories);
    const mainPageBlockConfig = useSWRImmutable('mainPageBlocks', getMainPageBlockConfig);
    const navigationConfig = useSWRImmutable('navigationConfig', getNavigationConfig);

    useEffect(() => {
        if (!navigationConfig.isLoading && !mainPageBlockConfig.isLoading) {
            ejectLoader();
        }
    }, [navigationConfig, getMainPageBlockConfig]);

    return useMemo(() => ({
        config,
        rankings,
        categories,
        navigationConfig,
        mainPageBlockConfig,
    }), [
        config,
        rankings,
        categories,
        navigationConfig,
        mainPageBlockConfig,
    ]);
}
