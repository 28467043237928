import { SVGProps } from 'react';

export function UpIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.99463 10.2246C4.82275 10.2246 4.68132 10.1691 4.57031 10.0581C4.46289 9.9471 4.40918 9.80387 4.40918 9.62842V2.96289L4.47363 1.21191L4.82812 1.38916L3.03418 3.38184L1.82031 4.58496C1.7666 4.63867 1.70394 4.68164 1.63232 4.71387C1.56071 4.74251 1.48372 4.75684 1.40137 4.75684C1.24023 4.75684 1.10596 4.70312 0.998535 4.5957C0.891113 4.48828 0.837402 4.35221 0.837402 4.1875C0.837402 4.02637 0.898275 3.88314 1.02002 3.75781L4.55957 0.212891C4.62044 0.152018 4.68848 0.105469 4.76367 0.0732422C4.83887 0.0410156 4.91585 0.0249023 4.99463 0.0249023C5.07699 0.0249023 5.15576 0.0410156 5.23096 0.0732422C5.30973 0.105469 5.37777 0.152018 5.43506 0.212891L8.97461 3.75781C9.09635 3.88314 9.15723 4.02637 9.15723 4.1875C9.15723 4.35221 9.10352 4.48828 8.99609 4.5957C8.88867 4.70312 8.75439 4.75684 8.59326 4.75684C8.5109 4.75684 8.43392 4.74251 8.3623 4.71387C8.29069 4.68164 8.22803 4.63867 8.17432 4.58496L6.95508 3.38184L5.16113 1.38916L5.521 1.21191L5.58545 2.96289V9.62842C5.58545 9.80387 5.52995 9.9471 5.41895 10.0581C5.31152 10.1691 5.17008 10.2246 4.99463 10.2246Z"
                fill="currentColor"
            />
        </svg>
    )
}
