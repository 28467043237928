export const MOCK_SHIMMERS_DATA = [
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
        path: "/",
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
]


export const SHORTEN_SHIMMERS_DATA = [
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
    {
        title: 'Shimmers',
        description: 'Simple shimmer component. Simple shimmer component. Simple shimmer component.',
        loading: true,
    },
]
