import React from 'react';
import classNames from 'classnames';
import { TWAApplication } from '../../../../types/entities';

import { useOpenAppCommand } from '../../../hooks/app/useOpenAppCommand';
import { ProgressiveImage } from '../../../uilib/ProgressiveImage/ProgressiveImage';
import { Ripple } from '../../../uilib/Ripple/Ripple';
import { Caption } from '../../../uilib/Typography/Typography';
import styles from './styles.module.scss';

interface IRecentlyOpenedProps extends React.HTMLAttributes<HTMLDivElement> {
    app: TWAApplication;
    isLoading?: boolean;
}

export const RecentlyOpened: React.FC<IRecentlyOpenedProps> = (props) => {
    const { createOpenAppCommand } = useOpenAppCommand();
    const { app, isLoading } = props;

    const handleOpenAppCommand = () => {
        if (!isLoading) {
            createOpenAppCommand(app, "recent");
        }
    }

    return (
        <div
            onClick={() => handleOpenAppCommand()}
            className={classNames(styles.recentlyLaunchedItem, props.className)}
        >
            <Ripple className={styles.recentlyLaunchedRipple}>
                <div className={styles.iconContainer}>
                    <ProgressiveImage
                        width={64}
                        height={64}
                        src={app?.attributes?.icon?.data?.attributes.url}
                        alt={app.attributes.title || ""}
                        className={classNames(
                            styles.image,
                            isLoading && styles.shine
                        )}
                    />
                </div>
                <div className={styles.captionContainer}>
                    <Caption
                        level={1}
                        weight={"regular"}
                        className={classNames(
                            styles.caption,
                            isLoading && styles.shine,
                            isLoading && styles.captionIsLoading
                        )}
                    >
                        {!isLoading && app.attributes.title}
                    </Caption>
                </div>
            </Ripple>
        </div>
    )
}
