import { TWAApplicationImage } from '../../../types/entities';

export const MOCK_SCREENSHOTS: Array<TWAApplicationImage> = [
    {
        "attributes":
            {
                "name": "mock",
                "width":1125,
                "height":2436,
                "url": "",
            }
    },
    {
        "attributes":
            {
                "name": "mock",
                "width":1125,
                "height":2436,
                "url": "",
            }
    },
    {
        "attributes":
            {
                "name":"mock",
                "width":1125,
                "height":2436,
                "url": "",
            }
    }
]
