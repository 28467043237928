import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LOCALES, locales } from '../../../dataSource/LocaleContext/i18n';
import { useLocaleContext } from '../../../dataSource/LocaleContext/LocaleContext';
import { InfoIcon } from '../../../svg/InfoIcon';
import { Body } from '../../../uilib/Typography/Typography';
import { openTelegramLink } from '../../../utils/common';
import { PRIVACY_POLICY_URL } from '../../../utils/constatns';
import styles from '../page.module.scss';
import { Row } from './_components/Row/Select';
import { Header } from './_components/Header/Header';
import { Select } from './_components/Select/Select';

export type IFormValues = {
    locale: LOCALES,
};

export const SettingsPage = () => {
    const { changeLocale, locale } = useLocaleContext();
    const { t } = useTranslation();
    const {
        watch,
        register,
    } = useForm<IFormValues>({
        defaultValues: { locale }
    });

    useEffect(() => {
        if (locale !== watch().locale) {
            changeLocale!(watch().locale);
        }
    }, [watch().locale])

    return (
        <main className={styles.main}>
            <Header>
                {t("appLanguage")}
            </Header>
            <form>
            {
                Object.entries(locales).map(([locale, config]) => (
                    <Select
                        key={config.title}
                        title={config.title}
                        description={config.localName}
                        value={locale}
                        {...register("locale")}
                    />
                ))
            }
            </form>
            <Header>
                {t("appInfo")}
            </Header>
            <div>
                <Row
                    onClick={() => {
                        openTelegramLink(PRIVACY_POLICY_URL)
                    }}
                    left={
                        <span className={styles.icon}>
                            <InfoIcon/>
                        </span>
                    }
                    right={
                        <Body weight={"regular"}>
                            Privacy Policy
                        </Body>
                    }
                />
            </div>
        </main>
    )
}
