import { useCallback, useEffect, useRef } from 'react';
import { TWAApplication } from '../../../types/entities';
import { useDataContext } from '../../dataSource/DataContext/DataContext';
import { PAGE_SIZE } from '../AppList/TWAAppList';

const ROW_HEIGHT = 106;
const REQUIRED_PATH_DELTA = 0;

const updateHeaderHeight = (height: number) => {
    document.documentElement.style.setProperty("--header_height", `${height}px`);
}

export const useScrollBehaviour = () => {
    const visibleApplicationsRef = useRef<TWAApplication[]>([]);
    const scroll = useRef(0);
    const headerHeight = useRef<number|undefined>(undefined);
    const recentlyLaunchedHeight = useRef<number|undefined>(undefined);

    const onScroll = useCallback(
        () => {
            headerHeight.current = document.querySelector('nav')?.clientHeight;
            recentlyLaunchedHeight.current = document.getElementById('recently__launched__container')?.clientHeight;

            if (!headerHeight.current) {
                return;
            }

            const scrollContainer = document.querySelector("main");
            const nextCord = scrollContainer?.scrollTop ?? 0;

            if (Math.abs(scroll.current - nextCord) < REQUIRED_PATH_DELTA) {
                return;
            }

            if (nextCord <= 0) {
                updateHeaderHeight(headerHeight.current);

                return;
            }

            if (nextCord <= REQUIRED_PATH_DELTA) {
                updateHeaderHeight(headerHeight.current);
            } else {
                if (nextCord + scrollContainer!.offsetHeight >= PAGE_SIZE * ROW_HEIGHT) {
                    updateHeaderHeight(0);
                    return;
                }

                updateHeaderHeight(0);
            }

            scroll.current = nextCord;
        }, [])

    const { visibleApplications } = useDataContext();

    useEffect(() => {
        document.querySelector('main')?.addEventListener("scroll", onScroll);

        return () => {
            document.querySelector('main')?.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        visibleApplicationsRef.current = visibleApplications;
    }, [visibleApplications])
}
