import React, { PropsWithChildren } from 'react';
import { AppConfig, AppConfigData } from '../../types/entities';
import { DEFAULT_APP_CONFIG } from '../utils/constatns';

interface IConfigContext {
    config?: AppConfigData;
}

const ConfigContext= React.createContext<IConfigContext>({
    config: DEFAULT_APP_CONFIG,
});

interface IConfigProviderProps {
    config?: AppConfig | null;
}

export const ConfigProvider: React.FC<PropsWithChildren<IConfigProviderProps>> = (props) => {
    return (
        <ConfigContext.Provider
            value={{
                config: props.config?.attributes,
            }}
        >
            {props.children}
        </ConfigContext.Provider>
    )
};

export const useConfigContext = () => React.useContext(ConfigContext);
