// refs: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript

import isMobile from './isMobile'

export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

let scrollBarWidth: number = 0
export const checkScrollbarWidth = () => {
    if (isMobile(navigator).any) {
        return
    }

    document.documentElement.style.setProperty('--scrollbar-width', `0px`)

    if (!scrollBarWidth) {
        scrollBarWidth = getScrollbarWidth()
    }

    if (document.body.clientWidth === window?.innerWidth) {
        document.documentElement.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`)
    }
}

let timeoutId: NodeJS.Timeout | null = null;

export function ejectLoader() {
    const loader = document.querySelector('.appLoader');

    if (loader && !timeoutId) {
        timeoutId = setTimeout(() => {
            loader.classList.add('fading');

            setTimeout(() => {
                loader.classList.add('hidden');
                timeoutId = null;
            }, 200);
        }, 300)
    }
}
