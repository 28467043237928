import classNames from 'classnames';
import React from 'react';
import { motion } from 'framer-motion';
import { useTWAPlatform } from '../../hooks'
import { TWASearch } from '../Search/TWASearch/TWASearch';
import { useScrollBehaviour } from './useScrollBehaviour';
import styles from './styles.module.scss';

export const TWAHeader = () => {
    const platform = useTWAPlatform();

    useScrollBehaviour();

    return (
        <>
            <nav className={classNames(styles.root, platform === 'android' && styles.android)}>
                <TWASearch platform={platform}/>
            </nav>
            <div id={"searchResultsPortal"} />
            <div className={styles.placeholder}/>
        </>
    )
}
