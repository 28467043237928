import React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../../uilib/Typography/Typography';
import { MOCK_RECENTLY_LAUNCHED_APPS } from '../../../utils/mocks/recentlyLaunched';
import { RecentlyOpened } from './RecentlyLaucnhedApp';
import styles from './styles.module.scss';

export const RecentlyLaunchedPlaceholder = () => {
    const { t } = useTranslation();

    return (
        <div
            className={styles.root}
            id={"recently__launched__container"}
        >
            <Heading
                type={"h4"}
                weight={"bold"}
                className={styles.recentlyLaunchedHeader}
            >
                {t("recentlyOpened")}
            </Heading>
            <div className={styles.appContainer}>
                {
                    MOCK_RECENTLY_LAUNCHED_APPS.map((app) => (
                            <RecentlyOpened
                                isLoading
                                key={app.id}
                                app={app}
                            />
                        )
                    )
                }
            </div>
        </div>
    )
}
