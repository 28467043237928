export function getRotateAnimationConfig(index: number) {
    if (index === 0) {
        return {
            initial: {
                rotate: '-6deg'
            },
            animate: {
                rotate: 0
            },
            transition: {
                type: 'spring',
                stiffness: 700,
                damping: 20,
            },
        }
    }

    if (index === 1) {
        return {
            initial: {
                rotate: '-13deg',
            },
            animate: {
                rotate: 0,
            },
            transition: {
                type: 'spring',
                stiffness: 700,
                damping: 10,
            },
        }
    }

    return NO_ANIMATION_CONFIG;
}

export const NO_ANIMATION_CONFIG = {
    initial: {
        rotate: '0',
    },
    animate: {
        rotate: '0',
    },
    transition: {
        type: 'spring',
        damping: 10,
        stiffness: 700,
    },
}
