import React from 'react';
import classNames from 'classnames';

import { useDataContext } from '../../dataSource/DataContext/DataContext';
import { Action } from '../Action/Action';
import { getIcon } from '../Icon/Icon';
import { Callout } from '../Typography/Typography';
import styles from './styles.module.scss';

interface ITWATabsProps extends React.HTMLAttributes<HTMLDivElement> {
    smoothScroll?: boolean;
}

export const Tabs: React.FC<ITWATabsProps> = (props) => {
    const {
        navigationConfig,
        setActiveCategory,
    } = useDataContext();

    return (
        <div className={classNames(styles.root, props.className)}>
            {
                navigationConfig
                    ?.filter((category) => category.attributes.path)
                    .map((category, index) => {
                        const title = category.attributes.title;

                        return (
                            <Action
                                key={index}
                                onClick={() => setActiveCategory(category)}
                                icon={getIcon(category)}
                                className={classNames(styles.item, index === 0 && styles.firstItem)}
                            >
                                <Callout
                                    weight={"medium"}
                                    className={styles.title}
                                >
                                    {title}
                                </Callout>
                            </Action>
                        )
                    })
            }
        </div>
    );
}
