import React from 'react'
import { ClearIcon } from '../../../../svg/ClearIcon'
import { Spinner } from '../../../Spinner/Spinner'
import styles from './styles.module.scss'

interface IControlsProps {
    value: string;
    isLoading: boolean;
    onActionClick: React.MouseEventHandler<SVGSVGElement>;
}

export const Controls: React.FC<IControlsProps> = (props) => {
    const { value, isLoading, onActionClick } = props;

    return value && value.length > 0
        ? (
            isLoading
                ? <Spinner />
                : <ClearIcon
                    className={styles.clearIcon}
                    onClick={onActionClick}
                    width={17}
                    height={17}
                    tabIndex={0}
                />
        )
        : null
}
