import useSWR from 'swr';
import { getApplicationByPath } from '../../utils/api/requests';

export const useAppViewInitAction = (path: string) => {
    const { data, error, isLoading } = useSWR(path, getApplicationByPath);

    return {
        isLoading,
        data,
        error
    }
}
