import { TelegramWebApps } from '../../../types/common';
import { AppConfig, TWAApplication, TWACategory, TWARankings } from '../../../types/entities';
import system from '../../services/System';
import { getEntities, getEntitiesWithStringQuery, getEntity } from './entities';

export const getRankings = () => getEntity<TWARankings>(
    'ranking',
    {
        populate: [ 'applications' ]
    }
)
    .then((ranking) => ranking?.data?.attributes.applications.data.map((app: TWAApplication) => app.id) ?? [])
    .catch((e) => {
        // TODO: add sentry logging
        if (process.env.NODE_ENV === 'development') {
            console.log(e);
        }
    });

export const getApplications = () => getEntities<Array<TWAApplication>>('applications', {
    sort: ['editors_choice', 'createdAt:desc'],
}).catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e);
        }
    });

export const getApplicationsWithPagination = (url:string) => getEntitiesWithStringQuery<Array<TWAApplication>>(url)
    .then((res) => {
        res.data?.forEach((app) => {
            system.preloadImage(app.attributes.icon?.data?.attributes?.url!);
        });

        return res;
    })
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e);
        }
    });

export const getApplicationByPath = (path:string) => getEntities<Array<TWAApplication>>(
    'applications',
    {
        filters: {
            path: {
                $eq: path,
            },
        },
        populate: [
            'icon',
            'categories',
            'poster',
            'screenshots',
            'jetton',
        ],
    })
    .then(({ data }) => {
        if (data && data.length > 1) {
            throw new Error(`Multiple application instance exists with path: ${path}.`)
        }

        if (!data || data.length === 0) {
            return null;
        }

        return data[0];
    })
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e);
        }

        return null;
    });

export const getRecentlyLaunchedFromApi = (telegram_user_data: TelegramWebApps.WebAppUser) => {
    const query = {
        filters: {}
    };

    query.filters = {
        platforms: {
            name: {
                $eq: window.Telegram.WebApp.platform,
            }
        }
    }

    return fetch(
        '/api/applications/recentlyLaunched',
        {
            method: 'POST',
            body: JSON.stringify({
                telegram_user_data: telegram_user_data,
                query,
            }),
            headers: {
                "Content-Type": 'application/json',
                "x-init-data": window.Telegram.WebApp.initData,
            }
        })
        .then(res => res.json())
        .catch((e) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(e)
            }
        });
}

export const getCategories = () => getEntities<Array<TWACategory>>('categories', )
    .then((res) => {
        return {
            ...res,
            data: res.data?.sort((a, b) => {
                if (a.attributes?.order === null) return 1;
                if (b.attributes?.order === null) return -1;

                if (a.attributes?.order < b.attributes?.order) {
                    return -1;
                }

                if (a.attributes?.order > b.attributes?.order) {
                    return 1;
                }

                return 0;
            }),
        };
    })
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e);
        }
    })

export const getConfig = () => getEntity<AppConfig>(
    'app-config',
    {
        populate: ['recently_launched_enabled'],
    })
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e)
        }
    })

export const openApplication = (application: TWAApplication, telegram_user_data: TelegramWebApps.WebAppUser) => fetch(
    '/api/applications/open/',
    {
        method: 'POST',
        body: JSON.stringify({
            application,
            telegram_user_data,
        }),
    })
    .then(res => res.json())
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e)
        }
    })

export const storeApplication = (application: TWAApplication, telegram_user_data: TelegramWebApps.WebAppUser) => fetch(
    '/api/applications/recentlyLaunched/save',
    {
        method: 'POST',
        body: JSON.stringify({
            application,
            telegram_user_data,
        }),
        headers: {
            "Content-Type": 'application/json',
            "x-init-data": window.Telegram.WebApp.initData,
        }
    })
    .then(res => res.json())
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e)
        }
    })

export const getToncoinPrice = () => fetch(process.env.REACT_APP_TONCOIN_PRICE_API_URL!)
    .then(res => res.json())
    .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(e)
        }
    })
