import { useRef } from 'react';
import { TWAApplication } from '../../../types/entities';
import { storeApplication } from '../../utils/api/requests';
import { useTWAUser } from '../index';

export const useStoreAppLaunchCommand = () => {
    let { current: isLoading } = useRef(false);
    const twaUser = useTWAUser();

    const createStoreAppLaunchCommand = (application: TWAApplication) => {
        if (!isLoading) {
            if (!twaUser) {
                return;
            }

            isLoading = true;

            if (application) {
                storeApplication(application, twaUser)
                    .then((result) => {
                        isLoading = false;
                    })
                    .catch((e) => {
                        isLoading = false;
                    });
            }

            isLoading = false;
        }
    };

    return {
        createStoreAppLaunchCommand,
    };
}
