import React, { FormEventHandler, RefObject, useState } from 'react'
import { useDataSource } from '../../../dataSource/dataSource'

export const useSearch = (inputRef: RefObject<HTMLInputElement>) => {
    const [value, setValue] = useState('');
    const [isActive, setIsActive] = useState(false);

    const {
        data,
        reset,
        isStarted,
        isLoading,
        requestData,
        isCompleted,
    } = useDataSource();

    const handleChange: FormEventHandler<HTMLInputElement> = (e) => {
        setValue(e.currentTarget.value);
    }

    const resetInputValue = () => {
        setValue('');
    }

    const handleCancel:React.EventHandler<any> = (e) => {
        e.preventDefault();
        resetInputValue();
        inputRef.current?.blur();
        changeSearchState(false);
        reset();
    }

    const changeSearchState = (searchIsActive:boolean) => {
        setIsActive(searchIsActive)
        reset();
    }

    const blur = () => {
        inputRef.current?.blur();
    }

    return {
        data,
        blur,
        value,
        reset,
        isActive,
        isLoading,
        isStarted,
        requestData,
        handleChange,
        isCompleted,
        handleCancel,
        resetInputValue,
        changeSearchState,
    }
}
