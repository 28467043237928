import React from 'react'
import { CrossIcon } from '../../../../svg/CrossIcon'
import { Ripple } from '../../../Ripple/Ripple'
import { Spinner } from '../../../Spinner/Spinner'
import styles from './styles.module.scss'

interface IAndroidControlsProps {
    value: string;
    isLoading: boolean;
    onActionClick: React.MouseEventHandler<HTMLDivElement>;
}

export const AndroidControls: React.FC<IAndroidControlsProps> = (props) => {
    const { value, isLoading, onActionClick } = props;

    return value && value.length > 0
        ? (
            isLoading
                ? (
                    <div className={styles.spinnerWrapper}>
                        <Spinner className={styles.androidSpinner}/>
                    </div>
                )
                : (
                    <Ripple className={styles.androidRippleIcon} onClick={onActionClick}>
                        <CrossIcon
                            className={styles.clearIcon}
                            width={24}
                            height={24}
                        />
                    </Ripple>
                )
        )
        : null
}
