import { TG_START_APP_PARAM, TG_WEB_APP_START_PARAM } from '../../utils/constatns'

export class StartAppDataAdapter {
    private readonly startAppData: string|null = null;

    constructor() {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has(TG_WEB_APP_START_PARAM)) {
            this.startAppData = searchParams.get(TG_WEB_APP_START_PARAM);
        }

        if (searchParams.has(TG_START_APP_PARAM)) {
            this.startAppData = searchParams.get(TG_START_APP_PARAM);
        }
    }

    public get() {
        return this.startAppData;
    }

}
