// @ts-ignore TODO(Dimitreee): remove ignore after @types/beautiful-react-hooks will be updated, or replace the lib
import React, { useEffect } from 'react'

export const useTouchOutside = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
    const handleTouchOutside = (e: TouchEvent) => {
        if (!ref.current || ref.current.contains(e.target as Node)) {
            return
        }

        callback();
    }

    useEffect(() => {
        document.addEventListener('touchstart', handleTouchOutside);

        return () => {
            document.removeEventListener('touchstart', handleTouchOutside);
        }
    }, [])
}
