import { SVGProps } from 'react'

export const ClearIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9.5 19C14.1944 19 18 15.1943 18 10.5C18 5.80566 14.1944 2 9.5 2C4.80557 2 1 5.80566 1 10.5C1 15.1943 4.80557 19 9.5 19ZM13.2657 6.73438C13.5781 7.04663 13.5781 7.55322 13.2657 7.86572L10.6314 10.5L13.2657 13.1343C13.5781 13.4468 13.5781 13.9534 13.2657 14.2656C12.9533 14.5781 12.4467 14.5781 12.1343 14.2656L9.5 11.6313L6.86569 14.2656C6.55328 14.5781 6.04675 14.5781 5.73431 14.2656C5.42191 13.9534 5.42191 13.4468 5.73431 13.1343L8.36862 10.5L5.73431 7.86572C5.42191 7.55322 5.42191 7.04663 5.73431 6.73438C6.04672 6.42188 6.55325 6.42188 6.86569 6.73438L9.5 9.36865L12.1343 6.73438C12.4467 6.42188 12.9533 6.42188 13.2657 6.73438Z"
                  fill="currentColor"
            />
        </svg>

    )
}
