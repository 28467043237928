import React, { useCallback, useEffect, useState } from 'react';
import { TWAApplication } from '../../../../types/entities';
import { ApplicationProvider } from '../../../dataSource/ApplicationContext';
import { Footer } from '../../../uilib/Footer/Footer';
import {
    AppContainer,
    PadlessSectionContainer,
    SectionContainer,
} from '../../_components/AppContainer/AppContainer';
import { AppHeader } from '../../_components/AppHeader/AppHeader';
import { AppInfo } from '../../_components/AppInfo/AppInfo';
import { Description } from '../../_components/Description/Description';
import { JettonInfo } from '../../_components/JettonInfo/JettonInfo';
import { Promo } from '../../_components/Promo/Promo';
import { Screenshots } from '../../_components/Screenshots/Screenshots';
import { TWARecommendations } from '../../_components/TWARecommendations/Recommendations';

import styles from '../page.module.scss';

interface IApplicationMainScreenProps {
    data: TWAApplication | null | undefined,
    isLoading: boolean,
}

const ApplicationMainScreen: React.FC<IApplicationMainScreenProps> = (props) => {
    const { data } = props;
    const [
        element,
        setElement
    ] = useState<HTMLDivElement | null>(null);

    const ref = useCallback((element: HTMLDivElement | null) => {
        if (element) {
            setElement(element);
        }
    }, []);

    useEffect(() => {
        element?.scrollTo({
            left: 0,
            top: 0,
        })
    }, [data, element]);

    return (
        <ApplicationProvider
            application={data || null}
            isLoading={props.isLoading}
        >
            <main className={styles.main} ref={ref}>
                <AppContainer>
                    <SectionContainer>
                        <AppHeader />
                    </SectionContainer>
                    <SectionContainer>
                        <AppInfo/>
                    </SectionContainer>
                    <PadlessSectionContainer>
                        <Screenshots/>
                    </PadlessSectionContainer>
                    <SectionContainer>
                        <JettonInfo/>
                    </SectionContainer>
                    <SectionContainer>
                        <Description/>
                    </SectionContainer>
                    <TWARecommendations />
                    <Promo />
                </AppContainer>
                <div className={styles.footerWrapper}>
                    <Footer />
                </div>
            </main>
        </ApplicationProvider>
    )
};

export default ApplicationMainScreen;
