import { config } from 'dotenv'
import React from 'react';
import { BlockData } from '../../../../types/Blocks';
import { MAIN_PAGE } from '../../../utils/constatns'
import { CategoryBlock } from '../CategoryBlock/CategoryBlock';
import { FeaturingBlock } from '../FeaturingBlock/FeaturingBlock';
import styles from './styles.module.scss';

export interface IBlockProps {
    blockData: BlockData;
    index: number;
    config?: BlockData[];
}

export const Block: React.FC<IBlockProps> = (props) => {
    const { blockData } = props;

    const isCategory = assertCategory(blockData);
    const isFeature = assertIsFeature(blockData);

    if (isCategory) {
        return (
            <CategoryBlock
                category={blockData?.attributes?.category?.data!}
                analytics_prefix={MAIN_PAGE}
                withSeparator={assertSeparatorVisible(props.index, props.config!)}
                compressedSeparator={assertCategoryIsCompact(props.index - 1, props.config!)}
            />
        )
    }

    if (isFeature) {
        const includeSeparator = window.isRecentlyLaunchedExists && props.index === 0;

        return (
            <>
                {includeSeparator && <div className={styles.offset}></div>}
                <FeaturingBlock feature={blockData?.attributes?.feature!}/>
            </>
        )
    }

    return null;
}

function assertSeparatorVisible (index: number, config: BlockData[]) {
    if (window.isRecentlyLaunchedExists) {
        return true;
    }

    return Boolean(index !== 0 && assertCategory(config![index - 1]));
}

function assertCategory (blockData?: BlockData) {
    if (!blockData) {
        return false;
    }

    return Boolean(blockData?.attributes?.category?.data !== null && blockData?.attributes?.category?.data);
}

function assertIsFeature (blockData?: BlockData) {
    if (!blockData) {
        return false;
    }

    return Boolean(blockData?.attributes?.feature !== null && blockData?.attributes?.feature);
}

function assertCategoryIsCompact (index: number, config: BlockData[]) {
    const blockData = config[index];

    if (!blockData) {
        return false;
    }

    return Boolean(blockData?.attributes?.category?.data?.attributes?.compact);
}
