import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TWAApplication } from '../../../../types/entities';
import { useOpenAppCommand } from '../../../hooks/app/useOpenAppCommand';
import system from '../../../services/System';
import { ArrowDownIcon } from '../../../svg/ArrowDownIcon';
import { Button } from '../../Button/Button';
import { CompactApplicationCard } from '../../CompactApplicationCard/CompactApplicationCard';
import { ProgressiveImage } from '../../ProgressiveImage/ProgressiveImage';
import { Body } from '../../Typography/Typography';
import { AnimatedSpinner } from './AnimatedSpinner';
import { getRotateAnimationConfig, NO_ANIMATION_CONFIG } from './generateAnimationConfig';

import styles from './styles.module.scss';

interface IVisibilityHandler {
    itemKey: string;
    expanded?: boolean;
    loadMore: () => void;
    applications?: TWAApplication[] | null | undefined;
    isNextPageDataLoaded?: boolean;
    isLoadingMore?: boolean
}

// @ts-ignore
export const LoadMoreButton: React.FC<IVisibilityHandler> = (props) => {
    const [expanded, setExpanded] = useState(props.expanded);
    const { t } = useTranslation();
    const { createOpenAppCommand } = useOpenAppCommand();
    const isLoaded = system.storage.isKeyExist(props.itemKey);

    useEffect(() => {
        if (expanded && !props.isLoadingMore) {
            system.storage.setItem(props.itemKey, true);
        }
    }, [expanded, props.itemKey, props.isLoadingMore])

    const handleLoadMoreClick = () => {
        if (props.isLoadingMore) {
            return;
        }

        setExpanded(true);
        props.loadMore();
        window.Telegram?.WebApp?.HapticFeedback?.impactOccurred('light');
    }

    if (!props.applications) {
        return <></>;
    }

    if ((isLoaded || !props.isLoadingMore) && expanded) {
        return (
            props.applications?.map((item, index) => {
                const shouldAnimate = !isLoaded;
                const config = shouldAnimate
                    ? getRotateAnimationConfig(index)
                    : NO_ANIMATION_CONFIG;

                return (
                    <CompactApplicationCard
                        key={item.id}
                        application={item}
                        icon={
                            <motion.div
                                initial={config!.initial}
                                animate={config!.animate}
                                transition={config!.transition}
                                key={item.id}
                            >
                                <ProgressiveImage
                                    src={item.attributes.icon.data?.attributes.url}
                                    alt={item.attributes.icon.data?.attributes.name || ''}
                                    width={48}
                                    height={48}
                                    className={styles.image}
                                />
                            </motion.div>
                        }
                        onButtonClick={() => {
                            createOpenAppCommand(item);
                        }}
                    />
                )
            })
        )
    }

    return (
        <div className={styles.root}>
            <div
                className={styles.collider__container}
                onClick={handleLoadMoreClick}
            >
                {
                    props.applications.map((item, index) => {
                        if (index > 1) {
                            return null;
                        }

                        return (
                            <ProgressiveImage
                                key={item.id}
                                src={item.attributes.icon.data?.attributes.url}
                                alt={item.attributes.icon.data?.attributes.name || ''}
                                width={48}
                                height={48}
                                className={classNames(
                                    styles.image,
                                    styles.collider,
                                    styles.collider__item,
                                    {
                                        [styles.collider__item__1]: index === 0,
                                        [styles.collider__item__2]: index === 1,
                                    }
                                )}
                            />
                        )
                    })
                }
            </div>
            <Button
                type={'alternative'}
                onClick={handleLoadMoreClick}
                className={styles.collider__button}
            >
                <Body weight={'medium'}>
                    {t('seeMoreApps')}
                    {!props.isLoadingMore && <ArrowDownIcon/>}
                </Body>
                {props.isLoadingMore &&  <AnimatedSpinner/>}
            </Button>
        </div>
    );
};
