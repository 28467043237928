'use client';

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { TWAApplication } from '../../../../../types/entities'
import { DownIcon } from '../../../../svg/DownIcon'
import { UpIcon } from '../../../../svg/UpIcon'
import { isNewApp } from '../../../../utils/dates'
import { MOCK_SHIMMERS_DATA } from '../../../../utils/mocks/shimmers'
import { ApplicationCard } from '../../../ApplicationCard/ApplicationCard'
import { EmptySearch } from '../../../EmptySearch/EmptySearch'
import { Hint } from '../../../Hint/Hint'
import { Caption } from '../../../Typography/Typography'
import styles from './styles.module.scss'

interface ISearchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
    data: Array<TWAApplication>;
    visible: boolean;
    searchValue: string;
    searchIsCompleted: boolean;
    renderCard?: (props: TWAApplication, index: number) => React.ReactNode;
    // TODO(Dimitreee): add types
    renderShimmer?: (props: {title: string, description: string, loading: boolean, path?: string}, index: number) => React.ReactNode;
}

export const SearchResults: React.FC<ISearchResultsProps>= (props) => {
    const {
        data,
        visible,
        searchValue,
        searchIsCompleted,
    } = props;

    const [className, setClassName] = useState(classNames([styles.root]));

    useEffect(() => {
        if (visible) {
            setClassName(classNames([styles.root, styles.visible]));
        } else {
            setClassName(classNames([styles.root]));
        }
    }, [visible])

    return searchIsCompleted
        ? (
            <div className={classNames(styles.hintVisible, className, props.className)}>
                {data.length === 0 && (<EmptySearch value={searchValue}/>)}
                <div className={styles.scrollBlock}>
                    {
                        data.map((item, index) => {
                            if (props.renderCard) {
                                return props.renderCard(item, index);
                            }

                            const { attributes } = item;

                            return (
                                <ApplicationCard
                                    url={attributes.url}
                                    key={item.attributes.title + index}
                                    isNew={isNewApp(attributes.createdAt)}
                                    title={attributes.title}
                                    description={attributes.description}
                                    editorsChoice={Boolean(attributes.editors_choice)}
                                    path={attributes.path}
                                    img={{
                                        src: attributes?.icon?.data?.attributes.url ?? "",
                                        name: attributes?.icon?.data?.attributes.name ?? "",
                                    }}
                                />
                            )
                        })
                    }
                </div>
                {data.length !== 0 && (
                    <div className={styles.hintHolder}>
                        <div className={styles.hintRow}>
                            <div className={styles.hintGroup}>
                                <Hint>
                                    <UpIcon/>
                                </Hint>
                                <Hint>
                                    <DownIcon/>
                                </Hint>
                            </div>
                            <Caption level={2} weight={'regular'} className={styles.hintCaption}>
                                to navigate
                            </Caption>
                        </div>
                        <div className={styles.hintRow}>
                            <div className={styles.hintGroup}>
                                <Hint>Enter</Hint>
                            </div>
                            <Caption level={2} weight={'regular'} className={styles.hintCaption}>
                                to select
                            </Caption>
                        </div>
                    </div>
                )}
            </div>
        )
        : (
            <div className={classNames(styles.hintVisible, className, props.className)}>
                <div className={styles.scrollBlock}>
                    {
                        MOCK_SHIMMERS_DATA.map((item, index) => {
                            if (props.renderShimmer) {
                                return props.renderShimmer(item, index);
                            }

                            return ( <ApplicationCard key={index} {...item}/>)
                        })
                    }
                </div>
            </div>
        );
}
