import { motion } from 'framer-motion';
import classNames from 'classnames';
import React from 'react';

import { isNewApp } from '../../utils/dates';
import { TWAApplication } from '../../../types/entities';
import { MOCK_SHIMMERS_DATA } from '../../utils/mocks/shimmers';
import { ApplicationCard } from '../ApplicationCard/ApplicationCard';
import { useOpenAppCommand } from '../../hooks/app/useOpenAppCommand';
import { TWAApplicationCard } from '../ApplicationCard/TWAApplicationCard';

import styles from './styles.module.scss';

interface ITWAAppListProps extends React.HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean,
    applications?: Array<TWAApplication>,
}

export const PureTWAAppList: React.FC<ITWAAppListProps> = React.memo((props) => {
    const { createOpenAppCommand } = useOpenAppCommand();

    if (props.isLoading) {
        const shimmersToRender = MOCK_SHIMMERS_DATA

        return (
            <div className={classNames(styles.root, styles.twa, props.className)}>
                {
                    shimmersToRender.map((item, index) => {
                        return (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0.4 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.30}}
                            >
                                <ApplicationCard
                                    key={index}
                                    {...item}
                                />
                            </motion.div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            <div className={classNames(styles.root, styles.twa, props.className)}>
                {
                    props.applications?.map((item, index) => {
                        const { attributes } = item;

                        return (
                            <TWAApplicationCard
                                key={item.id}
                                title={attributes.title}
                                isNew={isNewApp(attributes.createdAt)}
                                description={attributes.description}
                                editorsChoice={Boolean(attributes.editors_choice)}
                                path={attributes.path}
                                url={attributes.url}
                                onButtonClick={() => {
                                    createOpenAppCommand(item);
                                }}
                                img={{
                                    src: attributes?.icon?.data?.attributes.url ?? "",
                                    name: attributes?.icon?.data?.attributes.name ?? "",
                                }}
                            />
                        )
                    })
                }
            </div>
        </>
    );
}, (prev, next) => prev.isLoading === next.isLoading);

PureTWAAppList.displayName = 'PureTWAAppList';
