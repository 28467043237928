import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAttributeFromSearch } from '../../utils/common';

import {
    APP_KEY_NAME,
    RECENT_KEY_NAME,
    SECTION_KEY_NAME,
    RECENT_CATEGORIES_PATH,
    TWA_APPLICATIONS_ROOT_PATH,
} from '../../utils/constatns';
import { getEcoStartAppParam } from '../../utils/getStartAppParam';
import { StartAppDataAdapter } from './getStartAppData'

export function useDeeplinkMiddleware() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const startupData = new StartAppDataAdapter().get();

        if (startupData) {
                // TODO: move to function
                const params = startupData.split('-');
                const section = getAttributeFromSearch(params, SECTION_KEY_NAME);

                if (section !== null) {
                    if (location.pathname.includes(section)) {
                        return;
                    }

                    if (section === RECENT_KEY_NAME) {
                        navigate(RECENT_CATEGORIES_PATH + `?tgWebAppStartParam=${startupData}`);

                        return;
                    }

                    navigate(`/${section}?tgWebAppStartParam=${startupData}`);

                    return;
                }

                // TODO: move to function
                const app = getAttributeFromSearch(params, APP_KEY_NAME);

                if (app !== null) {
                    if (location.pathname.includes(app)) {
                        return;
                    }

                    navigate(`/${TWA_APPLICATIONS_ROOT_PATH}/${app}?tgWebAppStartParam=${startupData}`);

                    return;
                }

                const ecosystem = getEcoStartAppParam();

                if (ecosystem !== null) {
                    window.ecosystem = ecosystem;
                }
            }
    }, []);
}

