import mixpanel from 'mixpanel-browser';
import { useCallback, useRef } from 'react'
import { TelegramWebApps } from '../../../types/common'
import { AppType, TWAApplication } from '../../../types/entities';

import { useConfigContext } from '../../dataSource/ConfigContext';
import { useDataContext } from '../../dataSource/DataContext/DataContext';
import { openApplication } from '../../utils/api/requests';
import { openTelegramLink } from '../../utils/common';
import { APIResponse } from '../../utils/constatns';
import { useTWAUser } from '../index';
import { useStoreAppLaunchCommand } from './useStoreAppCommand';

export const useOpenAppCommand = () => {
    let { current: isLoading } = useRef(false);

    const appConfig = useConfigContext();
    const twaUser = useTWAUser();
    const dataContext = useDataContext();
    const { createStoreAppLaunchCommand } = useStoreAppLaunchCommand();

    const createOpenAppCommand = useCallback((application?: TWAApplication | null | undefined, category?: string) => {
        if (!application) return;

        const { attributes } = application;

        // @ts-ignore
        const categoryFromState = dataContext && dataContext?.getActiveCategory();
        const categoryToTrack = category || categoryFromState?.attributes.title;

        if (!isLoading) {
            notifyAppLaunch(application, twaUser);

            mixpanel.track("App Launch", {
                "app_name": attributes.title,
                "app_id": application.id,
                "section": categoryToTrack,
            })

            if (!twaUser) {
                createStoreAppLaunchCommand(application);

                openTelegramLink(
                    generateOpenAppUrl(
                        attributes.url,
                        categoryToTrack,
                        "bot",
                        attributes?.startParam,
                    )
                );

                return;
            }

            if (attributes.webapp_url) {
                createStoreAppLaunchCommand(application);

                openTelegramLink(
                    generateOpenAppUrl(
                        attributes.webapp_url,
                        categoryToTrack,
                        "tma",
                        attributes?.startParam,
                    )
                );

                return;
            }

            isLoading = true;

            if (appConfig?.config?.open_app_in_chat && application.attributes?.poster?.data) {
                createStoreAppLaunchCommand(application);

                openApplication(application, twaUser)
                    .then((result) => {
                        isLoading = false;

                        if (result.data !== APIResponse.OK) {
                            openTelegramLink(
                                generateOpenAppUrl(
                                    attributes.url,
                                    categoryToTrack,
                                    "bot",
                                    attributes?.startParam,
                                )
                            );
                        }
                    })
                    .catch((e) => {
                        isLoading = false;

                        openTelegramLink(
                            generateOpenAppUrl(
                                attributes.url,
                                categoryToTrack,
                                "bot",
                                attributes?.startParam,
                            )
                        );

                    });
            } else {
                createStoreAppLaunchCommand(application);

                openTelegramLink(
                    generateOpenAppUrl(
                        attributes.url,
                        categoryToTrack,
                        "bot",
                        attributes?.startParam,
                    )
                );

            }

            isLoading = false;
        }
    }, [])

    return {
        createOpenAppCommand,
    };
}

const generateOpenAppUrl = (url:string, category: string | undefined, type: AppType, startParam?: string) => {
    const [preparedUrl,] = url.split('?');
    const nextUrl = new URL(preparedUrl);
    const parsedCategory = category ? category.replace(/ /g, '_') : 'empty';
    const startParamValue = startParam || `tapps_${parsedCategory}`;

    if (type === "tma") {
        nextUrl.searchParams.set('startapp', startParamValue);
    }

    if (type === "bot") {
        nextUrl.searchParams.set('start', startParamValue);
    }

    return nextUrl.toString();
}

const notifyAppLaunch = (application: TWAApplication, user?: TelegramWebApps.WebAppUser | undefined) => {
    if (!user) {
        return;
    }

    if ([559052, 212875893].includes(Number(<number>user?.id))) {
        try {
            fetch('/api/applications/open', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    application: application,
                    telegram_user_data: user,
                }),
            })
        } catch (e) {
        }
    }
}
