import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { LocaleProvider } from './dataSource/LocaleContext/LocaleContext'
import { storeBlumAppLaunch } from './hooks/useDeeplinkMiddleware/storeBlumAppLaunch'
import { initSentry } from './utils/init/sentry';

initSentry();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

const I18nApp = () => {
    return (
        <LocaleProvider>
            <App />
        </LocaleProvider>
    )
}

root.render(
    <React.StrictMode>
        <I18nApp />
    </React.StrictMode>
);
